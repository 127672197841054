<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة مادة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="materialName" label="اسم المادة"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="buyPrice" label="سعر الشراء"></v-text-field>

              <v-autocomplete rounded v-model="clinicId" :items="clinics" item-text="clinicName" item-value="idClinic" outlined label="العيادة"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة عيادة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    clinics: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    materialName: "",
    buyPrice: "",
    clinicId: "",
    files: [],
  }),
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        materialName: self.materialName,
        buyPrice: self.buyPrice,
        clinicId: self.clinicId,
      };

      console.log(data2);

      if (self.materialName && self.buyPrice && self.clinicId) {
        let data = {
          materialName: self.materialName,
          buyPrice: self.buyPrice,
          clinicId: self.clinicId,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addMaterial`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
