<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قبض يومي</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined type="number" v-model="incomeAmount" label="المبلغ"></v-text-field>

              <v-autocomplete rounded v-model="patientId" :items="patients" item-text="patientName" item-value="idPatient" outlined label="اسم المراجع"></v-autocomplete>

              <v-autocomplete rounded v-model="doctorId" :items="doctors" item-text="userName" item-value="idUser" outlined label="الطبيب"></v-autocomplete>

              <v-autocomplete rounded v-model="materialId" :items="materialFilter" item-text="materialName" item-value="idMaterialDoctor" outlined label="المادة او التحليل"></v-autocomplete>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="incomeNote" name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة قبض يومي</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    patients: Array,
    doctors: Array,
    doctorMaterials: Array,
  },
  data: () => ({
    show: false,
    overlay: false,

    showDate: false,
    isDoctorChosen: false,
    incomeAmount: "",
    generationTypeId: "",
    incomeNote: "",
    patientId: "",
    doctorId: "",
    materialId: "",
  }),
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        amount: self.incomeAmount,
        notes: self.incomeNote,
        materialDoctorId: self.materialId,
        patientId: self.patientId,
        isValid: 1,
        isReceived: 0,
        createdBy: self.$store.state.userData.idUser,
      };

      console.log(data2);

      if (self.incomeAmount && self.patientId && self.doctorId) {
        let data = {
          amount: self.incomeAmount,
          notes: self.incomeNote,
          materialDoctorId: self.materialId,
          patientId: self.patientId,
          isValid: 1,
          isReceived: 0,
          createdBy: self.$store.state.userData.idUser,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addMoneyIncome`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
    doctorChosen() {
      let filterDa;
    },
  },
  computed: {
    materialFilter() {
      if (this.doctorId) {
        return this.doctorMaterials.filter((item) => {
          return item.doctorId == this.doctorId;
        });
      } else {
        return this.doctorMaterials;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
