<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        تقارير الطلبة
        <v-spacer></v-spacer>
        <SearchStudentReport :trainingCenterId="trainingCenter" @clicked="onSearch" />
      </v-card-title>
      <v-data-table :headers="headers" :items="items" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <h4 style="padding: 0 3px">
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="dialog = false">
                <DeleteStudentPayment :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
              </v-btn>
            </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SearchStudentReport from "./report/SearchStudentReport.vue";
export default {
  components: {
    SearchStudentReport,
  },
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    overlay: false,
    search: "",
    headers: [
      { text: "رقم الوصل", value: "idStudentPayment" },
      { text: "تاريخ الدفعة", value: "paymentDateFormat" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "المبلغ", value: "amount" },
      { text: "اسم الكورس", value: "courseName" },
      { text: "اسم المستلم", value: "userName" },
      { text: "الملاحظات", value: "notes" },
    ],
    items: [],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      self.items = value;
      console.log(value);
    },
    allStudentPayments() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/studentPaymentsForCourse?studentId=${self.studentId}&courseId=${self.courseId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.studentPayments = res.data;
        });
    },
    searchPayment(value) {
      let self = this;
      console.log(value);
      self.studentPayments = value.data;
      self.studentId = value.studentId;
      self.courseId = value.courseId;
      self.showAddPayment = true;
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.resources.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.sectionName.toLowerCase().includes(v));
        });
      } else {
        return this.resources;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
