<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="info" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الحجوزات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="date" label="التاريخ" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="info" @click="$refs.dialogDate.save(date)"> موافق </v-btn>
                  <v-btn text color="info" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="info" text @click="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="info" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-calendar-search </v-icon>
        </v-btn>
      </template>
      <span>بحث في الحجوزات</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    clinics: Array,
    doctors: Array,
    patients: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    clinicId: "",
    patientId: "",
    doctorId: "",
    showDate: false,
    showTime: false,
    time: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
  }),

  methods: {
    searchData() {
      let self = this;

      self.$http
        .get(
          `${self.$store.state.apiUrl}/patientReservesDate/${self.date}`,

          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          this.$emit("searchData", res.data);
        });

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
