<template>
  <div style="margin-top: 10px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row v-if="!overlay">
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="primary">
          <v-row class="pa-5">
            <v-col cols="3">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-5 mb-5" color="warning">mdi-archive-refresh</v-icon>
            </v-col>
            <v-col cols="9" style="text-align: center" class="pa-5">
              <h4 style="font-size: 1.4em; font-weight: lighter; color: aliceblue !important">الصندوق الرئيسي</h4>
            </v-col>
          </v-row>

          <h4 style="font-size: 2.5em; font-weight: lighter; color: aliceblue !important">
            {{
              (income - expenses).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="primary">
          <v-row class="pa-5">
            <v-col cols="3">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-5 mb-5" color="warning">mdi-archive-arrow-down</v-icon>
            </v-col>
            <v-col cols="9" style="text-align: center" class="pa-5">
              <h4 style="font-size: 1.4em; font-weight: lighter; color: aliceblue !important">الصندوق الايرادات</h4>
            </v-col>
          </v-row>

          <h4 style="font-size: 2.5em; font-weight: lighter; color: aliceblue !important">
            {{
              (income * 1).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="primary">
          <v-row class="pa-5">
            <v-col cols="3">
              <v-icon style="background: white" class="pa-5 elevation-6 radius-5 mb-5" color="warning">mdi-archive-arrow-up</v-icon>
            </v-col>
            <v-col cols="9" style="text-align: center" class="pa-5">
              <h4 style="font-size: 1.4em; font-weight: lighter; color: aliceblue !important">صندوق المصروفات</h4>
            </v-col>
          </v-row>

          <h4 style="font-size: 2.5em; font-weight: lighter; color: aliceblue !important">
            {{
              (expenses * 1).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    income: 0,
    expenses: 0,
    overlay: true,
  }),
  created() {
    let self = this;
    self.$http
      .get(`${self.$store.state.apiUrl}/statistics`, {
        headers: { Authorization: `Bearer ${self.$store.state.user}` },
      })
      .then((res) => {
        self.income = res.data.income;
        self.expenses = res.data.expenses;
        self.overlay = false;
      })
      .catch((err) => {
        self.overlay = false;
      });
  },
};
</script>

<style lang="scss" scoped></style>
