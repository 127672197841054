import { render, staticRenderFns } from "./Income.vue?vue&type=template&id=7dd673a8&scoped=true"
import script from "./Income.vue?vue&type=script&lang=js"
export * from "./Income.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd673a8",
  null
  
)

export default component.exports