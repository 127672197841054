<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة حجز</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <!-- <v-text-field rounded outlined v-model="patientName" label="اسم المراجع"></v-text-field> -->
              <v-autocomplete rounded v-model="patientId" :items="patients" item-text="patientName" item-value="idPatient" outlined label="المراجع"></v-autocomplete>

              <v-autocomplete rounded v-model="clinicId" :items="clinics" item-text="clinicName" item-value="idClinic" outlined label="العيادة"></v-autocomplete>

              <v-autocomplete rounded v-model="doctorId" :items="doctors" item-text="userName" item-value="idUser" outlined label="الطبيب"></v-autocomplete>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="date" label="تاريخ الحجز" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(date)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-dialog ref="dialog" v-model="showTime" :return-value.sync="time" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="time" outlined rounded label="وقت الحجز" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="showTime" v-model="time" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save(time)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showTime = false"> الغاء </v-btn>
                </v-time-picker>
              </v-dialog>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="notes" name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة حجز</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    clinics: Array,
    doctors: Array,
    patients: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    clinicId: "",
    patientId: "",
    doctorId: "",
    showDate: false,
    showTime: false,
    time: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    notes: "",
  }),

  methods: {
    saveData() {
      let self = this;

      let data2 = {
        patientId: self.patientId,
        reservedDate: self.date,
        reservedTime: self.time,
        isEntered: 0,
        clinicId: self.clinicId,
        doctorId: self.doctorId,
        notes: self.notes,
      };

      console.log(data2);

      if (self.patientId && self.clinicId && self.doctorId && self.date && self.time && self.notes) {
        let data = {
          patientId: self.patientId,
          reservedDate: self.date,
          reservedTime: self.time,
          isEntered: 0,
          clinicId: self.clinicId,
          doctorId: self.doctorId,
          notes: self.notes,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addPatientReserved`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
