import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Company from "../views/Company.vue";
import Edo from "../views/Edo.vue";
import Pharmacy from "../views/Pharmacy.vue";
import TurathTraining from "../views/TurathTraining.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
  },
  {
    path: "/training",
    name: "training",
    component: TurathTraining,
  },
  {
    path: "/edo",
    name: "edo",
    component: Edo,
  },
  {
    path: "/pharmacy",
    name: "pharmacy",
    component: Pharmacy,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
