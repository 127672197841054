<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="info" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الاطباء</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete rounded v-model="doctor" :items="doctors" item-text="userName" item-value="idUser" return-object outlined label="الاطباء"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="info" text @click="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="info" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-account-search </v-icon>
        </v-btn>
      </template>
      <span>بحث في الاطباء</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    doctors: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    doctor: "",
    showDate: false,
    showTime: false,
    doctorMaterial: [],
    materials: [],
  }),

  methods: {
    searchData() {
      let self = this;

      let data = JSON.stringify(self.doctor.clinics.map((clinic) => clinic.clinicId));
      let formatData = data.replaceAll("[", "(");

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/doctorMaterialsById/${self.doctor.idUser}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/materialsForClinic/${formatData.replaceAll("]", ")")}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((doctorMaterial, materials) => {
            self.doctorMaterial = doctorMaterial.data;
            self.materials = materials.data;
            console.log(materials.data);
            this.$emit("searchData", { doctorMaterial: doctorMaterial.data, materials: materials.data, doctor: self.doctor });
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
