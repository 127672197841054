<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat v-if="$store.state.userLogIn == true">
      <v-container class="py-0 fill-height" fluid>
        <v-btn tile text :style="styleButton" @click="clickMenu({ name: 'center' })" v-if="$store.state.userData.roleId == 1 || $store.state.userData.roleId == 5">
          <v-icon left :color="styleButton != '' ? '#0096Ab' : '#111333'"> mdi-home-circle-outline </v-icon>
          <span :style="styleButton != '' ? 'color : #0096Ab' : 'color : #111333'">مركز التراث</span>
        </v-btn>
        <v-btn tile text :style="styleButtonTwo" @click="clickMenu({ name: 'centerTraining' })" v-if="$store.state.userData.roleId == 1">
          <v-icon left :color="styleButtonTwo != '' ? '#0096Ab' : '#111333'"> mdi-atom-variant </v-icon>
          <span :style="styleButtonTwo != '' ? 'color : #0096Ab' : 'color : #111333'"> مركز التراث للتدريب والتطوير</span>
        </v-btn>
        <v-btn
          tile
          text
          :style="styleButtonThree"
          @click="clickMenu({ name: 'edo' })"
          v-if="$store.state.userData.roleId == 1 || $store.state.userData.roleId == 5 || $store.state.userData.roleId == 7"
        >
          <v-icon left :color="styleButtonThree != '' ? '#0096Ab' : '#111333'"> mdi-atom </v-icon>
          <span :style="styleButtonThree != '' ? 'color : #0096Ab' : 'color : #111333'">ايدو بايونير</span>
        </v-btn>
        <v-btn tile text :style="styleButtonFour" @click="clickMenu({ name: 'pharmacy' })" v-if="$store.state.userData.roleId == 1">
          <v-icon left :color="styleButtonFour != '' ? '#0096Ab' : '#111333'"> mdi-pill </v-icon>
          <span :style="styleButtonFour != '' ? 'color : #0096Ab' : 'color : #111333'">الصيدلية</span>
        </v-btn>
        <v-btn tile text :style="styleButtonFive" @click="clickMenu({ name: 'company' })" v-if="$store.state.userData.roleId == 1 || $store.state.userData.roleId == 6">
          <v-icon left :color="styleButtonFive != '' ? '#0096Ab' : '#111333'"> mdi-domain </v-icon>
          <span :style="styleButtonFive != '' ? 'color : #0096Ab' : 'color : #111333'"> شركة اجيال الوطن</span>
        </v-btn>

        <v-spacer></v-spacer>

        <div style="margin: 0 20px">
          <img alt="Avatar" width="50" height="50" :src="imageSrc" @click="logOut" />
        </div>
      </v-container>
    </v-app-bar>

    <v-main style="background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)">
      <div v-if="$store.state.userLogIn == false">
        <router-view />
      </div>
      <v-container v-if="$store.state.userLogIn == true" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    styleButton: "border-bottom: 1px solid #0096Ab",
    styleButtonTwo: "",
    styleButtonThree: "",
    styleButtonFour: "",
    styleButtonFive: "",
    imageSrc: "",
  }),
  created() {
    let self = this;
    self.imageSrc = require("./assets/logo.png");
    this.$store.dispatch("getUserDate").then(() => {
      if (this.$store.state.user) {
        self.$store.state.userLogIn = true;
        self.$store.state.token = localStorage.getItem("centerLogin");
        if (this.$store.state.userData.roleId == 7) {
          self.$router.push("/edo").catch(() => {});
        } else {
          self.$router.push("/").catch(() => {});
        }
      } else {
        self.$router.push("/login").catch(() => {});
      }
    });
  },
  methods: {
    pageLink() {
      return "about";
    },
    logOut() {
      localStorage.removeItem("centerLogin");
      location.reload();
    },
    clickMenu(link) {
      let self = this;
      switch (link.name) {
        case "center":
          this.styleButton = "border-bottom: 1px solid #0096Ab";
          this.styleButtonTwo = "";
          this.styleButtonThree = "";
          this.styleButtonFour = "";
          this.styleButtonFive = "";
          this.$router.push("/").catch(() => {});
          self.imageSrc = require("./assets/logo.png");
          break;
        case "centerTraining":
          this.styleButton = "";
          this.styleButtonTwo = "border-bottom: 1px solid #0096Ab";
          this.styleButtonThree = "";
          this.styleButtonFour = "";
          this.styleButtonFive = "";
          this.$router.push("/training").catch(() => {});
          self.imageSrc = require("./assets/logo.png");
          break;
        case "edo":
          this.styleButton = "";
          this.styleButtonTwo = "";
          this.styleButtonThree = "border-bottom: 1px solid #0096Ab";
          this.styleButtonFour = "";
          this.styleButtonFive = "";
          this.$router.push("/edo").catch(() => {});
          self.imageSrc = require("./assets/logo.png");
          break;
        case "pharmacy":
          this.styleButton = "";
          this.styleButtonTwo = "";
          this.styleButtonThree = "";
          this.styleButtonFour = "border-bottom: 1px solid #0096Ab";
          this.styleButtonFive = "";
          this.$router.push("/pharmacy").catch(() => {});
          self.imageSrc = require("./assets/logo.png");
          break;
        case "company":
          this.styleButton = "";
          this.styleButtonTwo = "";
          this.styleButtonThree = "";
          this.styleButtonFour = "";
          this.styleButtonFive = "border-bottom: 1px solid #0096Ab";
          this.$router.push("/company").catch(() => {});
          self.imageSrc = require("./assets/company.png");
          break;
      }
    },
  },
};
</script>
<style>
@import url("./font.css");
.v-list--rounded {
  padding: 0px !important;
}

.v-list--rounded .v-list-item {
  border-radius: 32px 0px 0px 32px !important;
}
.v-application .primary {
  background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%) !important;
}
.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
}
</style>
