<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>الوصفة الطبية</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveReceipt"> حفظ </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>اضافة دواء</v-subheader>
          <v-list-item>
            <v-row>
              <v-col cols="6">
                <v-autocomplete rounded v-model="product" dense :items="products" return-object item-text="productName" item-value="idProduct" outlined label="اسم الدواء"></v-autocomplete>
                <v-textarea rounded rows="3" row-height="15" outlined v-model="productNotes" name="input-7-4" label="تعليمات الاستخدام"></v-textarea>
                <v-btn outlined rounded color="success" @click="AddMedicine"> اضافة دواء </v-btn>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="6">
                <h3>الادوية</h3>
                <div style="height: 10px"></div>
                <v-divider></v-divider>
                <div style="height: 20px"></div>
                <div v-if="medicines.length == 0">
                  <v-alert icon="mdi-close-circle" text type="error"> لاتوجد ادوية مسجلة </v-alert>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="2"><h5>اسم الدواء</h5></v-col>
                    <v-col cols="4"><h5>الجرعة</h5></v-col>
                    <v-col cols="4"><h5>تعليمات الاستخدام</h5></v-col>
                    <v-col cols="2"><h5>الاجراءات</h5></v-col>
                  </v-row>
                  <div style="height: 3px"></div>
                  <v-divider></v-divider>
                  <div style="height: 3px"></div>

                  <v-row v-for="(product, index) in medicines" :key="index">
                    <v-col cols="2">{{ product.productName }}</v-col>
                    <v-col cols="4">{{ product.productDose }}</v-col>
                    <v-col cols="4">{{ product.notes }}</v-col>
                    <v-col cols="2">
                      <v-icon @click="deleteClinic(product)" color="error"> mdi-delete-circle </v-icon>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>اضافة تحليل او مادة</v-subheader>
          <v-list-item>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      rounded
                      v-model="material"
                      dense
                      :items="materials"
                      return-object
                      item-text="materialName"
                      item-value="idMaterial"
                      outlined
                      label="التحليل او المادة"
                      @change="setPrice"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field rounded outlined type="number" dense v-model="price" label="السعر"></v-text-field>
                  </v-col>
                </v-row>

                <v-textarea rounded rows="3" row-height="15" outlined v-model="notes" name="input-7-4" label="الملاحظات"></v-textarea>

                <v-btn outlined rounded color="success" @click="AddMaterial"> اضافة مادة او تحليل </v-btn>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="6">
                <h3>التحليلات والمواد</h3>
                <div style="height: 10px"></div>
                <v-divider></v-divider>
                <div style="height: 20px"></div>
                <div v-if="analysis.length == 0">
                  <v-alert icon="mdi-close-circle" text type="error"> لاتوجد تحليلات مسجلة </v-alert>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="3"><h5>اسم المادة او التحليل</h5></v-col>
                    <v-col cols="3"><h5>اسم العيادة</h5></v-col>
                    <v-col cols="2"><h5>السعر</h5></v-col>
                    <v-col cols="3"><h5>الملاحظات</h5></v-col>
                    <v-col cols="1"><h5>الاجراءات</h5></v-col>
                  </v-row>
                  <div style="height: 3px"></div>
                  <v-divider></v-divider>
                  <div style="height: 3px"></div>

                  <v-row v-for="(material, index) in analysis" :key="index">
                    <v-col cols="3">{{ material.materialName }}</v-col>
                    <v-col cols="3">{{ material.clinicName }}</v-col>
                    <v-col cols="2">{{ material.price }}</v-col>
                    <v-col cols="3">{{ material.notes }}</v-col>
                    <v-col cols="1">
                      <v-icon @click="deleteMaterials(material)" color="error"> mdi-delete-circle </v-icon>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-2" outlined color="indigo" @click="dialog = true" v-bind="attrs" v-on="on"> اضافة وصفة طبية </v-btn>
      </template>
      <span>اضافة وصفة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    materials: Array,
    products: Array,
    patient: Object,
    doctorMaterials: Array,
  },
  data: () => ({
    dialog: false,
    widgets: false,
    sound: false,
    notifications: false,
    medicines: [],
    images: [],
    analysis: [],
    material: "",
    product: "",
    notes: "",
    price: "",
    productNotes: "",
  }),
  methods: {
    AddMaterial() {
      let self = this;
      let filterAnalysis = self.analysis.filter((a) => a.idMaterial == self.material.idMaterial);

      if (filterAnalysis.length == 0) {
        self.analysis.push({
          idMaterial: self.material.idMaterial,
          materialName: self.material.materialName,
          clinicName: self.material.clinicName,
          notes: self.notes,
          price: self.price,
        });
        self.price = "";
        self.notes = "";
        self.material = "";
      } else {
        self.$toasted.error("هذه المادة او التحليل موجود مسبقا");
      }
      console.log(filterAnalysis);
    },
    AddMedicine() {
      let self = this;
      let filterMedicines = self.medicines.filter((a) => a.idProduct == self.product.idProduct);
      console.log(filterMedicines);
      if (filterMedicines.length == 0) {
        self.medicines.push({ ...self.product, notes: self.productNotes });
        self.productNotes = "";
      } else {
        self.$toasted.error("هذا الدواء موجود مسبقا");
      }
    },
    deleteMaterials(item) {
      let self = this;
      self.analysis = self.analysis.filter((a) => a.idMaterial != item.idMaterial);
      console.log(item);
    },
    setPrice() {
      let self = this;
      console.log(self.material);
      self.price = self.doctorMaterials.find((m) => m.idMaterial == self.material.idMaterial).sellPrice;
    },
    saveReceipt() {
      let self = this;
      let data = {
        receiptDate: "",
        patientId: self.patient.idPatient,
        doctorId: self.$store.state.userData.idUser,
      };
      self.$http
        .post(`${self.$store.state.apiUrl}/addReceipt`, data, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          if (self.medicines.length > 0 && self.analysis.length > 0) {
            let newAnalysis = self.analysis.map((ma) => {
              return {
                receiptId: res.data.id,
                idMaterial: ma.idMaterial,
                price: ma.price,
                notes: ma.notes,
              };
            });

            let newMedicines = self.medicines.map((ma) => {
              return {
                receiptId: res.data.id,
                idProduct: ma.idProduct,
                notes: ma.notes,
              };
            });
            console.log(newMedicines);
            self.$http
              .post(`${self.$store.state.apiUrl}/addMultiReceiptProducts`, newMedicines, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((resProducts) => {
                self.$http
                  .post(`${self.$store.state.apiUrl}/addMultiReceiptMaterials`, newAnalysis, {
                    headers: { Authorization: `Bearer ${self.$store.state.user}` },
                  })
                  .then((resMaterials) => {
                    console.log(res.data);
                    console.log(resProducts.data);
                    console.log(resMaterials.data);
                    this.$toasted.success("تم اضافه البيانات");
                    this.$emit("reloadTask");
                  });
              });
          } else if (self.medicines.length > 0) {
            let newMedicines = self.medicines.map((ma) => {
              return {
                receiptId: res.data.id,
                idProduct: ma.idProduct,
                notes: ma.notes,
              };
            });
            self.$http
              .post(`${self.$store.state.apiUrl}/addMultiReceiptProducts`, newMedicines, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((resProducts) => {
                console.log(res.data);
                console.log(resProducts.data);
                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              });
          } else if (self.analysis.length > 0) {
            let newAnalysis = self.analysis.map((ma) => {
              return {
                receiptId: res.data.id,
                idMaterial: ma.idMaterial,
                price: ma.price,
                notes: ma.notes,
              };
            });
            self.$http
              .post(`${self.$store.state.apiUrl}/addMultiReceiptMaterials`, newAnalysis, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((resMaterials) => {
                console.log(res.data);
                console.log(resMaterials.data);
                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              });
          }
        });

      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
