import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrl: "http://localhost:5211/api",
    apiUrl: "https://turath.center/api",

    userLogIn: false,
    userData: null,
    showPatientHistory: false,
    showStudentCourses: false,
    genders: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],
  },
  getters: {},
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("centerLogin");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
