import { render, staticRenderFns } from "./StudentPaymentReport.vue?vue&type=template&id=fbe95f7e&scoped=true"
import script from "./StudentPaymentReport.vue?vue&type=script&lang=js"
export * from "./StudentPaymentReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbe95f7e",
  null
  
)

export default component.exports