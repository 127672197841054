<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة مراجع</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-combobox rounded v-model="patientName" :items="patients" item-text="patientName" outlined label="اسم المراجع"></v-combobox>

              <v-text-field rounded outlined type="tel" v-model="patientPhone" label="رقم الهاتف"></v-text-field>

              <v-text-field rounded type="date" outlined v-model="dob" label="تاريخ الميلاد"></v-text-field>

              <v-autocomplete rounded v-model="gender" :items="genders" item-text="name" item-value="id" outlined label="الجنس"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة مراجع</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    clinics: Array,
    patients: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    patientName: "",
    dob: "",
    patientPhone: "",
    clinicId: "",
    files: [],
    genders: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],
    gender: "",
  }),
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        patientName: self.patientName,
        patientPhone: self.patientPhone,
        dob: self.dob,
        patientPassword: "$2b$10$rz6Om2l3TfZG7JvHPacKH.Wpg1LO1nG4Q31dpKqd17/Q4iEe1N0RG",
        gender: self.gender,
      };

      console.log(data2);

      if (self.patientName && self.patientPhone && self.dob && self.gender) {
        let data = {
          patientName: self.patientName,
          patientPhone: self.patientPhone,
          dob: self.dob,
          patientPassword: "$2b$10$rz6Om2l3TfZG7JvHPacKH.Wpg1LO1nG4Q31dpKqd17/Q4iEe1N0RG",
          gender: self.gender,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addPatient`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
