<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-sheet rounded="xl" elevation="0">
          <v-list shaped style="border-radius: 20px !important">
            <v-subheader>القائمة الرئيسية</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary" mandatory>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col>
        <v-sheet min-height="70vh" rounded="xl" elevation="0">
          <Box v-if="selectedItem == 0" />
          <Expenses v-if="selectedItem == 1" />
          <Income v-if="selectedItem == 2" />
          <Types v-if="selectedItem == 3" />
          <Benefit v-if="selectedItem == 4" />
          <Resource v-if="selectedItem == 5" />
          <Contract v-if="selectedItem == 6" />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Box from "./../components/company/Box.vue";
import Expenses from "./../components/company/Expenses.vue";
import Income from "./../components/company/Income.vue";
import Types from "./../components/company/Types.vue";
import Benefit from "./../components/company/Benefit.vue";
import Resource from "./../components/company/Resource.vue";
import Contract from "./../components/company/Contract.vue";

export default {
  components: {
    Box,
    Benefit,
    Expenses,
    Types,
    Income,
    Resource,
    Contract,
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "الصندوق", icon: "mdi-box" },
      { text: "المصروفات", icon: "mdi-cash-multiple" },
      { text: "الايرادات", icon: "mdi-currency-usd" },
      { text: "التبويبات", icon: "mdi-chart-donut-variant" },
      { text: "الجهات المستفيدة", icon: "mdi-chart-donut-variant" },
      { text: "مصادر الايراد", icon: "mdi-chart-donut-variant" },
      { text: "عقود الشركة", icon: "mdi-chart-donut-variant" },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
