import { render, staticRenderFns } from "./DeleteIncome.vue?vue&type=template&id=1d061d7c&scoped=true"
import script from "./DeleteIncome.vue?vue&type=script&lang=js"
export * from "./DeleteIncome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d061d7c",
  null
  
)

export default component.exports