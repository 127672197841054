<template>
  <div>
    <v-container class="container" fluid>
      <v-row>
        <v-col cols="6" align="center" align-self="center" style="height: 100vh; padding-top: 10%; background-color: #ffffff">
          <v-container class="container" fluid>
            <div style="margin-bottom: 20px">
              <v-img :src="require('../assets/logo.png')" class="my-3" contain height="200" />
            </div>
            <div style="margin: auto; width: 500px">
              <v-text-field outlined v-model="userName" label="اسم المستخدم"></v-text-field>
              <v-text-field outlined v-model="password" type="password" label="كلمة المرور" @keyup.enter="login()"></v-text-field>

              <v-btn outlined large block @click="login" color="primary">
                <v-icon left> mdi-login-variant </v-icon>
                تسجيل دخول
              </v-btn>
            </div>
          </v-container>
        </v-col>
        <v-col cols="6" class="login-image"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    password: "",
    userName: "",
  }),
  methods: {
    login() {
      let self = this;
      // this.$router.push("/");
      if (self.userName && self.password) {
        let credentials = {
          password: self.password,
          userName: self.userName,
        };
        self.$http
          .post(`${self.$store.state.apiUrl}/login`, credentials)
          .then((res) => {
            localStorage.setItem("centerLogin", JSON.stringify(res.data.token));
            location.reload();
          })
          .catch((e) => {
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("الرجاء ادخال  اسم المستخدم وكلمة المرور");
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style>
.register:hover {
  cursor: pointer;
}
.login-image {
  margin: 0px !important;
  padding: 0px !important;
  background: url("./../assets/turath.jpg");
  background-size: cover !important;
}
</style>
