<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الفواتير
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <!-- <AddProduct @reloadTask="initialData()" /> -->
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.productImage`]="{ item }">
          <v-avatar size="40">
            <img :src="`${$store.state.apiUrl}/${item.productImage}`" alt="" />
          </v-avatar>
        </template>

        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="dialog = false">
            <DeleteProduct :dataToDelete="item" @reloadTask="initialData()" />
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
    search: "",
    invoices: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم المراجع", value: "patientName" },
      { text: "اسم الصيدلي", value: "createdBy" },
      { text: "تاريخ الفاتورة", value: "invoiceDate" },
      { text: "وقت الفاتورة", value: "invoiceTime" },
      //   { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/invoices`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.invoices = res.data;
          self.overlay = false;
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.invoices.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.sectionName.toLowerCase().includes(v));
        });
      } else {
        return this.invoices;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
