<template>
  <div>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color py-5" style="border-radius: 20px !important">
        خدمات الطبيب
        <v-spacer></v-spacer>
        <SearchDoctor @searchData="onSearch" :doctors="doctors" />
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>

    <!-- <table>
      <tr v-for="(mat, index) in materials" :key="index">
        <td>
          <span v-if="checkIfHaveTotal(item).isCorrect">
            <v-checkbox v-model="checkIfHaveTotal(item).data[0].isFromTotal" disabled></v-checkbox>
          </span>
          <span v-else>
            <v-checkbox :id="`input-total-${index}`"></v-checkbox>
          </span>
        </td>
        <td>
          <span v-if="checkIfHaveTotal(item).isCorrect"> {{ checkIfHaveTotal(item).data[0].doctorPercentage }}% </span>
          <span v-else>
            <v-text-field small type="number" :id="`input-deal-${index}`" label="نسبة الطبيب"></v-text-field>
          </span>
        </td>
        <td>
          <span v-if="checkIfHaveTotal(item).isCorrect">
            {{ checkIfHaveTotal(item).data[0].sellPrice }}
          </span>
          <span v-else>
            <v-text-field small type="number" :id="`input-sell-${index}`" label="سعر البيع"></v-text-field>
          </span>
        </td>
      </tr>
    </table> -->

    <v-data-table :headers="headers" :items="materials" :items-per-page="15" class="table-content table-warning-color">
      <template v-slot:[`item.index`]="{ index }">
        <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
      </template>

      <template v-slot:[`item.isFromTotal`]="{ item }">
        <span v-if="checkIfHaveTotal(item).isCorrect">
          <v-checkbox v-model="checkIfHaveTotal(item).data[0].isFromTotal" disabled></v-checkbox>
        </span>
        <span v-else>
          <v-checkbox v-model="isTotal"></v-checkbox>
        </span>
      </template>
      <template v-slot:[`item.doctorPercentage`]="{ item }">
        <span v-if="checkIfHaveTotal(item).isCorrect"> {{ checkIfHaveTotal(item).data[0].doctorPercentage }}% </span>
        <span v-else>
          <v-text-field small type="number" v-model="doctorPercentage" label="نسبة الطبيب"></v-text-field>
        </span>
      </template>

      <template v-slot:[`item.sellPrice`]="{ item }">
        <span v-if="checkIfHaveTotal(item).isCorrect">
          {{ checkIfHaveTotal(item).data[0].sellPrice }}
        </span>
        <span v-else>
          <v-text-field small type="number" v-model="sellPrice" label="سعر البيع"></v-text-field>
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <PatientEntered @reloadTask="initialData" :patient="item" style="display: inline-block" v-if="item.isEntered == 0" />
        <div style="width: 20px; display: inline-block"></div>
        <span v-if="checkIfHaveTotal(item).isCorrect">
          <v-icon color="error"> mdi-cancel </v-icon>
        </span>
        <span v-else>
          <v-icon @click="addMaterialDoctor(item)" color="success"> mdi-plus-circle </v-icon>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeleteReserve from "./reserves/DeleteReserve.vue";
import PatientEntered from "./reserves/PatientEntered.vue";
import SearchDoctor from "./doctorMaterial/SearchDoctor.vue";
export default {
  components: {
    DeleteReserve,
    PatientEntered,
    SearchDoctor,
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 7),
    materials: [],
    doctors: [],
    patients: [],
    clinics: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "اسم المادة", value: "materialName" },
      { text: "سعر الشراء", value: "buyPrice" },
      { text: "سعر البيع", value: "sellPrice" },
      { text: "نسبة الطبيب", value: "doctorPercentage" },
      { text: "هل النسبة كلية", value: "isFromTotal" },
      { text: "الاجراءات", value: "actions" },
    ],
    doctor: {},
    doctorMaterial: [],
    isTotal: false,
    sellPrice: "",
    doctorPercentage: "",
    doctorDetails: null,
  }),
  created() {
    this.initialData();
  },
  methods: {
    checkIfHaveTotal(item) {
      let self = this;
      if (self.doctorMaterial.length > 0) {
        let data = self.doctorMaterial.filter((mat) => mat.materialId == item.idMaterial);
        if (data.length > 0) {
          return { isCorrect: true, data: data };
        } else {
          return { isCorrect: false, data: null };
        }
      } else {
        return { isCorrect: false, data: null };
      }
    },
    addMaterialDoctor(item) {
      let self = this;

      if (self.doctorPercentage && self.sellPrice) {
        //addDoctorMaterial
        let data = {
          doctorId: self.doctor.idUser,
          materialId: item.idMaterial,
          sellPrice: self.sellPrice,
          doctorPercentage: self.doctorPercentage,
          isFromTotal: self.isTotal,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addDoctorMaterial`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }
    },
    onSearch(value) {
      let self = this;
      console.log(value);
      self.materials = value.materials;
      self.doctor = value.doctor;
      self.doctorMaterial = value.doctorMaterial;
    },
    initialData() {
      let self = this;

      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/reservesForToday`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/clinics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/patients`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/allDoctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((reserves, clinics, patients, doctors) => {
            self.reserves = reserves.data;
            self.patients = patients.data;
            self.clinics = clinics.data;
            self.doctors = doctors.data;
            console.log(reserves.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    datePicked() {
      console.log(this.picker);
    },
  },
};
</script>

<style lang="scss" scoped></style>
