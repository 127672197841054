<template>
  <v-row>
    <v-col cols="2">
      <v-sheet rounded="xl" elevation="0">
        <v-list shaped style="border-radius: 20px !important">
          <v-subheader>القائمة الرئيسية</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary" mandatory>
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-col>

    <v-col v-if="$store.state.userData.roleId != 7">
      <v-sheet min-height="70vh" rounded="xl" elevation="0">
        <Teachers :trainingCenter="2" v-if="selectedItem == 0" />
        <Courses :trainingCenter="2" v-if="selectedItem == 1" />
        <Students :trainingCenter="2" v-if="selectedItem == 2" />
        <!-- <StudentPayments :trainingCenter="2" v-if="selectedItem == 3" /> -->
        <StudentReceipt :trainingCenter="2" v-if="selectedItem == 3" />
        <TrainingExpenses :trainingCenter="2" v-if="selectedItem == 4" />
        <Boxes :trainingCenter="2" v-if="selectedItem == 5" />
        <ReportTraining :trainingCenter="2" v-if="selectedItem == 6" />
        <StudentPaymentReport :trainingCenter="2" v-if="selectedItem == 7" />
      </v-sheet>
    </v-col>

    <v-col v-else>
      <v-sheet min-height="70vh" rounded="xl" elevation="0">
        <Students :trainingCenter="2" v-if="selectedItem == 0" />
        <!-- <StudentPayments :trainingCenter="2" v-if="selectedItem == 3" /> -->
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import Teachers from "./../components/training/Teachers.vue";
import Students from "./../components/training/Students.vue";
import StudentPayments from "./../components/training/StudentPayments.vue";
import Courses from "./../components/training/Courses.vue";
import StudentReceipt from "./../components/training/StudentReceipt.vue";
import TrainingExpenses from "./../components/training/TrainingExpenses.vue";
import Boxes from "./../components/training/Boxes.vue";
import ReportTraining from "./../components/training/ReportTraining.vue";
import StudentPaymentReport from "./../components/training/StudentPaymentReport.vue";
export default {
  components: {
    Teachers,
    StudentPayments,
    Students,
    Courses,
    StudentReceipt,
    TrainingExpenses,
    Boxes,
    ReportTraining,
    StudentPaymentReport,
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "الاساتذة", icon: "mdi-box" },
      { text: "الكورسات", icon: "mdi-cash-multiple" },
      { text: "الطلبة", icon: "mdi-currency-usd" },
      // { text: "مقبوضات الطلبة", icon: "mdi-chart-donut-variant" },
      { text: "الايرادات", icon: "mdi-chart-donut-variant" },
      { text: "المصروفات", icon: "mdi-chart-donut-variant" },
      { text: "الصناديق", icon: "mdi-chart-donut-variant" },
      { text: "التقارير", icon: "mdi-chart-donut-variant" },
      { text: "تقارير الطلبة", icon: "mdi-chart-donut-variant" },
    ],
  }),
  created() {
    if (this.$store.state.userData.roleId == 7) {
      this.items = [{ text: "الطلبة", icon: "mdi-currency-usd" }];
    }
  },
};
</script>

<style lang="scss" scoped></style>
