<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة صرفية</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined type="number" v-model="expensesAmount" label="مبلغ الصرفية"></v-text-field>

              <v-autocomplete rounded v-model="currencyId" :items="currencies" item-text="currencyName" item-value="idCurrency" outlined label="العملة"></v-autocomplete>

              <v-autocomplete rounded v-model="generationTypeId" :items="types" item-text="typeName" item-value="idGenerationType" outlined label="التبويب"></v-autocomplete>

              <v-autocomplete rounded v-model="benefitId" :items="benefits" item-text="benefitName" item-value="idGenerationBenefit" outlined label="الجهة المستفيدة"></v-autocomplete>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="expensesDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="expensesDate" label="تاريخ الصرفية" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expensesDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(expensesDate)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="expensesNote" name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة صرفية</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    currencies: Array,
    benefits: Array,
    types: Array,
  },
  data: () => ({
    show: false,
    overlay: false,

    showDate: false,
    expensesDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    expensesAmount: "",
    generationTypeId: "",
    expensesNote: "",
    currencyId: "",
    benefitId: "",
  }),
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        expensesDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        expensesAmount: self.expensesAmount,
        generationTypeId: self.generationTypeId,
        expensesNote: self.expensesNote,
        currencyId: self.currencyId,
        generationBenefitId: self.benefitId,
        isValid: 1,
        createdBy: self.$store.state.userData.idUser,
      };

      console.log(data2);

      if (self.expensesAmount && self.generationTypeId && self.currencyId && self.benefitId) {
        let data = {
          expensesDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          expensesAmount: self.expensesAmount,
          generationTypeId: self.generationTypeId,
          expensesNote: self.expensesNote,
          currencyId: self.currencyId,
          generationBenefitId: self.benefitId,
          isValid: 1,
          createdBy: self.$store.state.userData.idUser,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addGenerationExpenses`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
