<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الايرادات
        <v-spacer></v-spacer>
        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddMoneyReceipt @reloadTask="initialData()" :doctors="doctors" :users="employees" :doctorMaterials="doctorMaterials" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <h4 style="padding: 0 3px">
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </template>

        <template v-slot:[`item.isExternal`]="{ item }">
          <span>
            {{ item.isExternal == 1 ? "قبض خارجي" : "قبض داخلي" }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <DeleteIncome @reloadTask="initialData" :dataToDelete="item" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddMoneyReceipt from "./moneyReceipt/AddMoneyReceipt.vue";
import DeleteIncome from "./income/DeleteIncome.vue";
export default {
  components: {
    AddMoneyReceipt,
    DeleteIncome,
  },
  data: () => ({
    overlay: false,
    search: "",
    moneyReceipts: [],
    doctors: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "المبلغ", value: "amount" },
      { text: "التاريخ", value: "createdAtFormat" },
      { text: "الموظف", value: "userName" },
      { text: "امين الصندوق", value: "createdByUser" },
      { text: "نوع القبض", value: "isExternal" },
      { text: "الملاحظات", value: "notes" },
      //   { text: "الاجراءات", value: "actions" },
    ],
    employees: [],
    doctorMaterials: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    todyReceipt() {
      let self = this;
      let date = new Date("2024-02-18");
      let today = `${date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
      console.log(today);
      let filterMoney = self.moneyReceipts.filter((income) => income.createdAtFormat == today);

      let sumAmount = filterMoney.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

      if (sumAmount) {
        return sumAmount;
      } else {
        return 0;
      }
    },
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/moneyReceipts`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/allDoctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/employees`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/doctorMaterials`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((moneyReceipts, doctors, employees, doctorMaterials) => {
            self.moneyReceipts = moneyReceipts.data;
            self.doctors = doctors.data;
            self.employees = employees.data;
            self.doctorMaterials = doctorMaterials.data;
            console.log(moneyReceipts.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.moneyReceipts.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.patientName.toLowerCase().includes(v));
        });
      } else {
        return this.moneyReceipts;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
