<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة عيادة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="clinicName" label="اسم العيادة"></v-text-field>

              <v-autocomplete rounded v-model="sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="clinicDescription" name="input-7-4" label="وصف العيادة"></v-textarea>

              <v-file-input rounded multiple label="صورة العيادة" outlined v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة عيادة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    clinicName: "",
    clinicDescription: "",
    clinicImage: "",
    sectionId: "",
    files: [],
  }),
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.clinicImage = res.data;
          console.log(res);
        });
      }
    },
    saveData() {
      let self = this;

      let data2 = {
        clinicName: self.clinicName,
        clinicDescription: self.clinicDescription,
        clinicImage: self.clinicImage.imagePath,
        sectionId: self.sectionId,
      };

      console.log(data2);

      if (self.clinicName && self.clinicDescription) {
        let data = {
          clinicName: self.clinicName,
          clinicDescription: self.clinicDescription,
          clinicImage: self.clinicImage.imagePath,
          sectionId: self.sectionId,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addClinic`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(self.clinicImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
