<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة استاذ </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="teacherName" label="اسم الاستاذ"></v-text-field>

              <v-text-field rounded outlined v-model="teacherPhone" label="رقم الهاتف"></v-text-field>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="date" label="المواليد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(date)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="teacherBio" name="input-7-4" label="نبذه عن الاستاذ"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة استاذ</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    show: false,
    overlay: false,
    teacherName: "",
    teacherPhone: "",
    teacherBio: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    showDate: false,
  }),
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        teacherName: self.teacherName,
        teacherPhone: self.teacherPhone,
        dob: self.date,
        teacherBio: self.teacherBio,
        trainingCenterId: self.trainingCenter,
      };

      console.log(data2);

      if (self.teacherName) {
        let data = {
          teacherName: self.teacherName,
          teacherPhone: self.teacherPhone,
          dob: self.date,
          teacherBio: self.teacherBio,
          trainingCenterId: self.trainingCenter,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addTeacher`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
