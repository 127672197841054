<template>
  <div>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color py-5" style="border-radius: 20px !important">
        تاريخ المراجع

        <v-spacer></v-spacer>

        <h4>اسم المراجع : {{ patient.patientName }}</h4>
        <span style="width: 50px"></span>
        <h4>الجنس : {{ patient.gender == 1 ? "ذكر" : "انثى" }}</h4>
        <span style="width: 50px"></span>
        <h4>المواليد : {{ patient.dobFormat }}</h4>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="backButton()" size="35" color="error" v-bind="attrs" v-on="on"> mdi-arrow-left-thin-circle-outline </v-icon>
          </template>
          <span>رجوع</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>
      <div style="height: 20px"></div>

      <div style="height: 20px"></div>

      <v-container fluid>
        <v-card v-for="(hsi, index) in history" :key="index" elevation="3" style="margin-bottom: 20px">
          <v-card-title class="warning-color" style="border-radius: 20px !important">
            <h4>اسم الطبيب : {{ hsi.userName }}</h4>
            <v-spacer></v-spacer>
            <h4>تاريخ الوصفة : {{ hsi.receiptDateFormat }}</h4>
          </v-card-title>
          <v-card v-if="hsi.receiptProducts?.length > 0" style="margin-bottom: 10px" elevation="1">
            <v-card-title class="warning-color" style="border-radius: 20px !important">
              الادوية
              <v-spacer></v-spacer>
              <div style="width: 20px"></div>
            </v-card-title>
            <v-data-table :headers="headersProduct" :items="hsi.receiptProducts" :items-per-page="15" class="table-content table-warning-color">
              <template v-slot:[`item.index`]="{ index }">
                <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
              </template>

              <template v-slot:[`item.gender`]="{ item }">
                <h4 style="padding: 0 3px">{{ item.gender == 1 ? "ذكر" : "انثى" }}</h4>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    patient: Object,
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 7),
    materials: [],
    products: [],
    history: [],
    headersProduct: [
      { text: "العدد", value: "index" },
      { text: "اسم الدواء", value: "productName" },
    ],
    headersMaterial: [
      { text: "العدد", value: "index" },
      { text: "اسم المادة او التحليل", value: "materialName" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    backButton() {
      let self = this;
      self.$store.state.showPatientHistory = false;
    },
    initialData() {
      let self = this;

      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/materials`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/products`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/receiptHistory/${self.patient.idPatient}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((materials, products, history) => {
            self.materials = materials.data;
            self.products = products.data;
            self.history = history.data;
            console.log(history.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    datePicked() {
      console.log(this.picker);
    },
  },
};
</script>

<style lang="scss" scoped></style>
