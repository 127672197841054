<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة كورس </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="courseName" label="اسم الكورس"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="duration" label="عدد ايام الكورس"></v-text-field>

              <v-text-field rounded outlined type="number" v-model="courseCost" label="التكلفة"></v-text-field>

              <v-autocomplete rounded v-model="teacherId" :items="teachers" item-text="teacherName" item-value="idTeacher" outlined label="الاستاذ"></v-autocomplete>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="startDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="startDate" label="المواليد" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(startDate)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="courseDescription" name="input-7-4" label="وصف الكورس"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة كورس</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    trainingCenter: Number,
    teachers: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    courseName: "",
    teacherPhone: "",
    courseDescription: "",
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    showDate: false,
    duration: "",
    teacherId: "",
    courseCost: "",
  }),
  methods: {
    saveData() {
      let self = this;

      if (self.courseName) {
        let data = {
          courseName: self.courseName,
          duration: self.duration,
          startDate: self.startDate,
          courseDescription: self.courseDescription,
          teacherId: self.teacherId,
          courseCost: self.courseCost,
          trainingCenterId: self.trainingCenter,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addCourse`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
