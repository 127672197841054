import { render, staticRenderFns } from "./ExpensesCenter.vue?vue&type=template&id=27d0d72c&scoped=true"
import script from "./ExpensesCenter.vue?vue&type=script&lang=js"
export * from "./ExpensesCenter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d0d72c",
  null
  
)

export default component.exports