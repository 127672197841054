<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة مصروف</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="totalAmount" type="number" label="المبلغ"></v-text-field>

              <v-autocomplete rounded v-model="typeId" :items="expensesTypes" item-text="typeName" item-value="idExpensesType" outlined label="التبويب"></v-autocomplete>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="notes" name="input-7-4" label="التفاصيل"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة مصروف</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    expensesTypes: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    showEmployee: false,
    notes: "",
    totalAmount: 0,
    typeId: "",
  }),

  methods: {
    saveData() {
      let self = this;

      let data2 = {
        amount: self.totalAmount,
        notes: self.notes,
        expensesTypeId: self.typeId,
        expensesDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        createdBy: self.$store.state.userData.idUser,
      };

      console.log(data2);

      if (self.totalAmount && self.notes) {
        let data = {
          amount: self.totalAmount,
          expensesDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          notes: self.notes,
          expensesTypeId: self.typeId,
          createdBy: self.$store.state.userData.idUser,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addExpensesCenter`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
  computed: {
    materialFilter() {
      if (this.typeId) {
        return this.doctorMaterials.filter((item) => {
          return item.typeId == this.typeId;
        });
      } else {
        return this.doctorMaterials;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
