<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الاطباء
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <v-scroll-y-transition mode="out-in">
          <AddDoctor @reloadTask="initialData" />
        </v-scroll-y-transition>
      </v-card-title>

      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.userImage`]="{ item }">
          <v-avatar size="40">
            <img :src="`${$store.state.apiUrl}/${item.userImage}`" alt="" />
          </v-avatar>
        </template>

        <template v-slot:[`item.dealPercentage`]="{ item }">
          <h4 style="padding: 0 3px">{{ item.dealPercentage }}%</h4>
        </template>

        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <AddDoctorClinics style="display: inline-block" :clinics="clinics" :doctor="item" />
          <DeleteDoctor @reloadTask="initialData" :dataToDelete="item" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddDoctor from "./doctors/AddDoctor.vue";
import DeleteDoctor from "./doctors/DeleteDoctor.vue";
import AddDoctorClinics from "./doctors/DoctorClinics.vue";
export default {
  components: {
    AddDoctor,
    DeleteDoctor,
    AddDoctorClinics,
  },
  data: () => ({
    overlay: false,
    search: "",
    doctors: [],
    clinics: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "صورة الطبيب", value: "userImage" },
      { text: "اسم الطبيب", value: "userName" },
      { text: "رقم الهاتف", value: "phone" },
      { text: "التخصص", value: "specialization" },
      { text: "نسبة الطبيب", value: "dealPercentage" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/allDoctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/clinics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((doctors, clinics) => {
            self.doctors = doctors.data;
            console.log(self.doctors);
            self.clinics = clinics.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.doctors.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.userName.toLowerCase().includes(v));
        });
      } else {
        return this.doctors;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
