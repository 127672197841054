<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة دفعة للطالب </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined type="number" v-model="amount" label="التكلفة"></v-text-field>

              <v-autocomplete rounded v-model="paymentTypeId" :items="types" item-text="paymentTypeName" item-value="idPaymentType" outlined label="الدفعة"></v-autocomplete>

              <v-dialog ref="dialogDate" v-model="showDate" :return-value.sync="paymentDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined rounded v-model="paymentDate" label="تاريخ الدفعة" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="paymentDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialogDate.save(paymentDate)"> موافق </v-btn>
                  <v-btn text color="primary" @click="showDate = false"> الغاء </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-textarea v-model="notes" rounded outlined name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة دفعة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    trainingCenter: Number,
    studentId: Number,
    courseId: Number,
    cost: Number,
    types: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    courseName: "",
    teacherPhone: "",
    courseDescription: "",
    paymentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    showDate: false,
    duration: "",
    paymentTypeId: "",
    amount: "",
    notes: "لايوجد",
  }),
  created() {
    this.amount = this.cost;
  },
  methods: {
    saveData() {
      let self = this;

      if (self.paymentDate && self.paymentTypeId && self.amount) {
        let data = {
          paymentDate: self.paymentDate,
          studentId: self.studentId,
          paymentTypeId: self.paymentTypeId,
          amount: self.amount,
          trainingCenterId: self.trainingCenter,
          courseId: self.courseId,
          createdBy: self.$store.state.userData.idUser,
          isReceived: 0,
          notes: self.notes,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addStudentPayment`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
