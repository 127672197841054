<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important" v-if="$store.state.showStudentCourses == false">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الطلبة
        <v-spacer></v-spacer>
        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddStudent @reloadTask="initialData()" :trainingCenter="trainingCenter" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.studentName`]="{ item }">
          <v-btn text @click="studentSelected(item)">{{ item.studentName }}</v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <StudentCourse :courses="courses" :studentId="item" style="display: inline-block" />
          <span width="30px"></span>
          <UpdateStudent :student="item" @reloadTask="initialData()" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
          <span width="30px"></span>
          <v-btn icon @click="dialog = false">
            <DeleteStudent :dataToDelete="item" @reloadTask="initialData()" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <StudentCourses v-else :student="selectStudent" />
  </div>
</template>

<script>
import AddStudent from "./student/AddStudent.vue";
import UpdateStudent from "./student/UpdateStudent.vue";
import DeleteStudent from "./student/DeleteStudent.vue";
import StudentCourse from "./student/StudentCourse.vue";
import StudentCourses from "./StudentCourses.vue";
export default {
  components: {
    AddStudent,
    DeleteStudent,
    StudentCourse,
    StudentCourses,
    UpdateStudent,
  },
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    overlay: false,
    search: "",
    courses: [],
    selectStudent: {},
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "رقم الهاتف", value: "studentPhone" },
      { text: "المواليد", value: "dobFormat" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    students: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/studentTrainingCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/courseTrainingCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((students, courses) => {
            self.students = students.data;
            self.courses = courses.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    studentSelected(student) {
      let self = this;
      self.$store.state.showStudentCourses = true;
      self.selectStudent = student;
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.students.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.studentName.toLowerCase().includes(v));
        });
      } else {
        return this.students;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
