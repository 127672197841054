<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete rounded v-model="currencyId" :items="currencies" item-text="currencyName" item-value="idCurrency" outlined label="العملة"></v-autocomplete>

              <v-autocomplete rounded v-model="generationResourceId" :items="resources" item-text="resourceName" item-value="idGenerationResource" outlined label="مصدر الايراد"></v-autocomplete>

              <v-dialog ref="dialog" v-model="datePickerSearchModel" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field rounded v-model="dateRangeText" label="اختر التاريخ من - الى" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePickerSearchModel = false"> الغاء </v-btn>
                  <v-btn text color="primary" @click="getDates"> موافق </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-filter-outline </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    currencies: Array,
    resources: Array,
  },
  data: () => ({
    show: false,
    overlay: false,

    showDate: false,
    incomeDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    incomeAmount: "",
    generationTypeId: "",
    incomeNote: "",
    currencyId: "",
    generationResourceId: "",

    datePickerSearchModel: false,
    dates: [],
  }),
  methods: {
    saveData() {
      let self = this;
      if (self.dates.length > 0) {
        let query = "";

        if (self.currencyId) {
          query += `&currencyId=${self.currencyId}`;
        }

        if (self.generationResourceId) {
          query += `&generationResourceId=${self.generationResourceId}`;
        }

        self.$http
          .get(`${self.$store.state.apiUrl}/generationIncomeFilter?dates=${JSON.stringify(self.dates)}${query}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            this.$emit("clicked", res.data);
          });

        this.show = false;
      } else {
        this.$toasted.error("الرجاء اختيار المحددات");
        this.show = false;
      }
    },
    getDates() {
      this.datePickerSearchModel = false;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.length > 0 ? this.dates.join(" - ") : "تاريخ من - الى";
    },
  },
};
</script>

<style lang="scss" scoped></style>
