<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        العيادات
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddClinic @reloadTask="initialData()" :sections="sections" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>
        <template v-slot:[`item.clinicImage`]="{ item }">
          <v-avatar size="40">
            <img :src="`${$store.state.apiUrl}/${item.clinicImage}`" alt="" />
          </v-avatar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <DeleteClinic @reloadTask="initialData" :dataToDelete="item" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddClinic from "./clinics/AddClinic.vue";
import DeleteClinic from "./clinics/DeleteClinic.vue";
export default {
  components: {
    AddClinic,
    DeleteClinic,
  },
  data: () => ({
    overlay: false,
    search: "",
    sections: [],
    clinics: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "الصورة", value: "clinicImage" },
      { text: "اسم العيادة", value: "clinicName" },
      { text: "وصف العيادة", value: "clinicDescription" },
      { text: "القسم", value: "sectionName" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/sections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/clinics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((sections, clinics) => {
            self.sections = sections.data;
            self.clinics = clinics.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.clinics.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.from.toLowerCase().includes(v));
        });
      } else {
        return this.clinics;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
