<template>
  <div>
    <v-dialog v-model="show" persistent max-width="900px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>عيادات الطبيب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete rounded v-model="clinicId" dense :items="clinics" item-text="clinicName" item-value="idClinic" outlined label="العيادات"></v-autocomplete>
              <v-btn outlined rounded color="success" @click="saveData"> اضافة عيادة </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <h3>العيادات المسجلة للطبيب</h3>
              <div style="height: 10px"></div>
              <v-divider></v-divider>
              <div style="height: 20px"></div>
              <div v-if="clinicsForDoctor.length == 0">
                <v-alert icon="mdi-close-circle" text type="error"> لاتوجد عيادات مسجلة </v-alert>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="5"><h5>اسم الطبيب</h5></v-col>
                  <v-col cols="5"><h5>اسم العيادة</h5></v-col>
                  <v-col cols="2"><h5>الاجراءات</h5></v-col>
                </v-row>
                <div style="height: 3px"></div>
                <v-divider></v-divider>
                <div style="height: 3px"></div>

                <v-row v-for="(doctor, index) in clinicsForDoctor" :key="index">
                  <v-col cols="5">{{ doctor.userName }}</v-col>
                  <v-col cols="5">{{ doctor.clinicName }}</v-col>
                  <v-col cols="2">
                    <v-icon @click="deleteClinic(doctor)" color="error"> mdi-delete-circle </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="show = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-1" small icon color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-bottle-tonic-plus </v-icon>
        </v-btn>
      </template>
      <span>عيادات الطبيب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    clinics: Array,
    doctor: Object,
  },
  data: () => ({
    show: false,
    overlay: false,
    clinicsForDoctor: [],
    userName: "",
    specialization: "",
    dealPercentage: "",
    clinicId: "",
    phone: "",
    userImage: "",
    files: [],
  }),
  created() {
    this.getDoctorClinics();
  },
  methods: {
    getDoctorClinics() {
      let self = this;

      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/clinicsDoctor/${self.doctor.idUser}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.clinicsForDoctor = res.data;
          self.overlay = false;
        });
    },
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.userImage = res.data;
          console.log(res);
        });
      }
    },
    deleteClinic(doctor) {
      let self = this;
      self.$http
        .delete(`${self.$store.state.apiUrl}/doctorClinic/${doctor.idDoctorClinic}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toasted.error("تم حذف البيانات");
          self.getDoctorClinics();
        });
    },
    saveData() {
      let self = this;

      let filterClinics = self.clinicsForDoctor.filter((clinic) => clinic.clinicId == self.clinicId);
      console.log(filterClinics);

      if (self.clinicId && filterClinics.length == 0) {
        let data = {
          doctorId: self.doctor.idUser,
          clinicId: self.clinicId,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addDoctorClinic`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
            this.getDoctorClinics();
          });
      } else {
        this.$toasted.error("حدث خطأ في حفظ البيانات او قد تكون العيادة مسجلة للطبيب");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
