<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قسم</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="sectionName" label="اسم القسم"></v-text-field>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="sectionDescription" name="input-7-4" label="وصف القسم"></v-textarea>

              <v-file-input rounded multiple label="صورة القسم" outlined v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة قسم</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    show: false,
    overlay: false,
    sectionName: "",
    sectionDescription: "",
    sectionImage: "",
    files: [],
  }),
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.sectionImage = res.data;
          console.log(res);
        });
      }
    },
    saveData() {
      let self = this;

      let data2 = {
        sectionName: self.sectionName,
        sectionDescription: self.sectionDescription,
        sectionImage: self.sectionImage.imagePath,
      };

      console.log(data2);

      if (self.sectionName && self.sectionDescription) {
        let data = {
          sectionName: self.sectionName,
          sectionDescription: self.sectionDescription,
          sectionImage: self.sectionImage.imagePath,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addSection`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(self.sectionImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
