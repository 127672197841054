<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-sheet rounded="xl" elevation="0">
          <v-list shaped style="border-radius: 20px !important">
            <v-subheader>القائمة الرئيسية</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary" mandatory>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col>
        <v-sheet min-height="70vh" rounded="xl" elevation="0">
          <Products v-if="selectedItem == 0" />
          <Patients v-if="selectedItem == 1" />
          <Invoices v-if="selectedItem == 2" />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Products from "./../components/pharmacy/Products.vue";
import Patients from "./../components/pharmacy/Patients.vue";
import Invoices from "./../components/pharmacy/Invoices.vue";
export default {
  components: {
    Products,
    Patients,
    Invoices,
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "الادوية", icon: "mdi-pill" },
      { text: "المراجعين", icon: "mdi-badge-account-horizontal" },
      { text: "الفواتير", icon: "mdi-printer-pos" },
      // { text: "التقارير", icon: "mdi-chart-donut-variant" },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
