<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        المصروفات
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab small outlined color="primary" @click="initialData()" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-repeat-variant </v-icon>
            </v-btn>
          </template>
          <span>اعادة</span>
        </v-tooltip>
        <FilterExpenses @clicked="onSearch" :benefits="benefits" :currencies="currencies" :types="types" />
        <AddExpenses @reloadTask="initialData()" :benefits="benefits" :currencies="currencies" :types="types" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- <Images :images="item.images" style="display: inline-block" /> -->

          <v-btn icon @click="dialog = false">
            <EditExpenses :itemToEdit="item" @reloadTask="initialData()" :benefits="benefits" :currencies="currencies" :types="types" v-if="$store.state.userData.roleId == 1" />
            <DeleteExpenses :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddExpenses from "./expenses/AddExpenses.vue";
import DeleteExpenses from "./expenses/DeleteExpenses.vue";
import EditExpenses from "./expenses/EditExpenses.vue";
import FilterExpenses from "./expenses/FilterExpenses.vue";

export default {
  components: {
    AddExpenses,
    DeleteExpenses,
    EditExpenses,
    FilterExpenses,
  },
  data: () => ({
    overlay: false,
    search: "",
    expenses: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "المبلغ", value: "expensesAmount" },
      { text: "التاريخ", value: "expensesDateFormat" },
      { text: "التبويب", value: "typeName" },
      { text: "التفاصيل", value: "expensesNote" },
      { text: "الجهة المستفيدة", value: "benefitName" },
      { text: "نوع العملة", value: "currencyName" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    benefits: [],
    types: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/allGenerationExpenses`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/currencies`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/generationBenefits`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/generationTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expenses, currencies, generationBenefits, generationTypes) => {
            self.expenses = expenses.data;
            self.currencies = currencies.data;
            self.benefits = generationBenefits.data;
            self.types = generationTypes.data;

            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    onSearch(value) {
      let self = this;
      self.expenses = value;
    },
  },

  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.expenses.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.expensesNote.toLowerCase().includes(v));
        });
      } else {
        return this.expenses;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
