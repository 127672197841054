<template>
  <div>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color py-5" style="border-radius: 20px !important">
        الحجوزات
        <v-spacer></v-spacer>
        <ReserveSearch @searchData="onSearch" :clinics="clinics" :doctors="doctors" :patients="patients" />
        <v-spacer></v-spacer>
        <AddReserve @reloadTask="initialData" :clinics="clinics" :doctors="doctors" :patients="patients" />
      </v-card-title>
    </v-card>

    <v-data-table :headers="headers" :items="reserves" :items-per-page="15" class="table-content table-warning-color">
      <template v-slot:[`item.index`]="{ index }">
        <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
      </template>

      <template v-slot:[`item.isEntered`]="{ item }">
        <span v-if="item.isEntered == 1">
          <v-icon color="success">mdi-account-check</v-icon>
        </span>
        <span v-else>
          <v-icon color="error">mdi-account-clock</v-icon>
        </span>
      </template>
      <template v-slot:[`item.reservedTime`]="{ item }">
        <h4>{{ tConvert(item.reservedTime) }}</h4>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <PatientEntered @reloadTask="initialData" :patient="item" style="display: inline-block" v-if="item.isEntered == 0" />
        <div style="width: 20px; display: inline-block"></div>
        <DeleteReserve @reloadTask="initialData" :dataToDelete="item" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AddReserve from "./reserves/AddReserve.vue";
import DeleteReserve from "./reserves/DeleteReserve.vue";
import PatientEntered from "./reserves/PatientEntered.vue";
import ReserveSearch from "./reserves/ReserveSearch.vue";
export default {
  components: {
    AddReserve,
    DeleteReserve,
    PatientEntered,
    ReserveSearch,
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 7),
    reserves: [],
    doctors: [],
    patients: [],
    clinics: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "اسم المراجع", value: "patientName" },
      { text: "تاريخ الحجز", value: "reservedDateFormat" },
      { text: "وقت الحجز", value: "reservedTime" },
      { text: "العيادة", value: "clinicName" },
      { text: "الطبيب", value: "userName" },
      { text: "تمت المراجعة", value: "isEntered" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    onSearch(value) {
      let self = this;
      console.log(value);
      self.reserves = value;
    },
    initialData() {
      let self = this;

      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/reservesForToday`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/clinics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/patients`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/allDoctors`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((reserves, clinics, patients, doctors) => {
            self.reserves = reserves.data;
            self.patients = patients.data;
            self.clinics = clinics.data;
            self.doctors = doctors.data;
            console.log(reserves.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    datePicked() {
      console.log(this.picker);
    },
  },
};
</script>

<style lang="scss" scoped></style>
