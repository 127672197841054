<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الجهة المستفيدة
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddBenefit @reloadTask="initialData()" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="dialog = false">
            <DeleteBenefit :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddBenefit from "./benefit/AddBenefit.vue";
import DeleteBenefit from "./benefit/DeleteBenefit.vue";
export default {
  components: {
    DeleteBenefit,
    AddBenefit,
  },
  data: () => ({
    overlay: false,
    search: "",
    benefits: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الجهة المستفيدة", value: "benefitName" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    resources: [],
    benefits: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/generationBenefits`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((benefits) => {
            self.benefits = benefits.data;

            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.benefits.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.benefitName.toLowerCase().includes(v));
        });
      } else {
        return this.benefits;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
