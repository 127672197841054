<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الايرادات
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab small outlined color="primary" @click="initialData()" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-repeat-variant </v-icon>
            </v-btn>
          </template>
          <span>اعادة</span>
        </v-tooltip>

        <FilterIncome @clicked="onSearch" :resources="resources" :currencies="currencies" />
        <AddIncome @reloadTask="initialData()" :resources="resources" :currencies="currencies" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- <Images :images="item.images" style="display: inline-block" /> -->

          <v-btn icon @click="dialog = false">
            <EditIncome :itemToEdit="item" @reloadTask="initialData()" :resources="resources" :currencies="currencies" v-if="$store.state.userData.roleId == 1" />
            <DeleteIncome :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddIncome from "./income/AddIncome.vue";
import EditIncome from "./income/EditIncome.vue";
import DeleteIncome from "./income/DeleteIncome.vue";
import FilterIncome from "./income/FilterIncome.vue";
export default {
  components: {
    AddIncome,
    DeleteIncome,
    EditIncome,
    FilterIncome,
  },
  data: () => ({
    overlay: false,
    search: "",
    expenses: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "المبلغ", value: "incomeAmount" },
      { text: "التاريخ", value: "incomeDateFormat" },
      { text: "التفاصيل", value: "incomeNote" },
      { text: "مصدر الايراد", value: "resourceName" },
      { text: "نوع العملة", value: "currencyName" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    resources: [],
    types: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/generationIncomes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/currencies`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/generationResources`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expenses, currencies, resources) => {
            self.expenses = expenses.data.reverse();
            self.currencies = currencies.data;
            self.resources = resources.data;

            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    onSearch(value) {
      let self = this;
      self.expenses = value;
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.expenses.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.incomeNote.toLowerCase().includes(v));
        });
      } else {
        return this.expenses;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
