<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة طبيب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined v-model="userName" label="اسم الطبيب"></v-text-field>

              <v-text-field rounded outlined v-model="specialization" label="التخصص"></v-text-field>

              <v-text-field rounded outlined v-model="dealPercentage" type="number" label="نسبة الطبيب"></v-text-field>

              <v-text-field rounded outlined v-model="phone" label="رقم الهاتف"></v-text-field>

              <!-- <v-autocomplete v-model="sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete> -->

              <v-file-input rounded multiple label="صورة الطبيب" outlined v-model="files" @change="getAllFiles"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة طبيب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    userName: "",
    specialization: "",
    dealPercentage: "",
    sectionId: "",
    phone: "",
    userImage: "",
    files: [],
  }),
  methods: {
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.userImage = res.data;
          console.log(res);
        });
      }
    },
    saveData() {
      let self = this;

      let data2 = {
        userName: self.userName,
        phone: self.phone,
        userImage: self.userImage.imagePath,
        password: "$2b$10$rz6Om2l3TfZG7JvHPacKH.Wpg1LO1nG4Q31dpKqd17/Q4iEe1N0RG",
        roleId: 2,
        specialization: self.specialization,
        dealPercentage: self.dealPercentage,
      };

      console.log(data2);

      if (self.userName && self.phone && self.specialization && self.dealPercentage) {
        let data = {
          userName: self.userName,
          phone: self.phone,
          userImage: self.userImage.imagePath,
          password: "$2b$10$rz6Om2l3TfZG7JvHPacKH.Wpg1LO1nG4Q31dpKqd17/Q4iEe1N0RG",
          roleId: 2,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addUser`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);
            self.$http
              .post(
                `${self.$store.state.apiUrl}/addDoctor`,

                {
                  userId: res.data.id,
                  specialization: self.specialization,
                  dealPercentage: self.dealPercentage,
                },
                {
                  headers: { Authorization: `Bearer ${self.$store.state.user}` },
                }
              )
              .then((doctorRes) => {
                console.log(doctorRes.data);

                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              });
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
