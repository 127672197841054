<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div style="height: 20px"></div>
    <h2 style="text-align: center">الصناديق</h2>
    <div style="height: 50px"></div>
    <v-row>
      <v-col cols="6">
        <v-card width="400px" style="margin: auto">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">صندوق الدينار</div>
              <v-list-item-title class="text-h5 mb-1">
                {{
                  (allExpenses.dinarReceipt - allExpenses.dinarExpenses).toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar size="80" color="primary">
              <v-icon size="50" color="background">mdi-cash</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card width="400px" style="margin: auto">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">صندوق الدولار</div>
              <v-list-item-title class="text-h5 mb-1">
                {{
                  (allExpenses.dollarReceipt - allExpenses.dollarExpenses).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar size="80" color="primary">
              <v-icon size="50" color="background">mdi-currency-usd</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
    allExpenses: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/expensesStatistics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((allExpenses) => {
            self.allExpenses = allExpenses.data;
            console.log(self.allExpenses);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// expensesStatistics
</style>
