<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="info" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>بحث في الطلبة</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete rounded v-model="studentId" :items="students" item-text="studentName" item-value="idStudent" outlined label="الطالب"></v-autocomplete>

              <v-autocomplete rounded v-model="courseId" :items="courses" item-text="courseName" item-value="idCourse" outlined label="الكورس"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="info" text @click="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="info" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-account-search </v-icon>
        </v-btn>
      </template>
      <span>بحث في الطلبة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    students: Array,
    courses: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    studentId: "",
    courseId: "",
    doctorId: "",
    showDate: false,
    showTime: false,
    time: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
  }),

  methods: {
    searchData() {
      let self = this;

      self.$http
        .get(`${self.$store.state.apiUrl}/studentPaymentsForCourse?studentId=${self.studentId}&courseId=${self.courseId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          this.$emit("searchData", { data: res.data, studentId: self.studentId, courseId: self.courseId });
        });

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
