<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قبض</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-autocomplete rounded v-model="typeId" @change="getIncomeType" :items="incomeType" item-text="name" item-value="id" outlined label="نوع القبض"></v-autocomplete>

              <v-autocomplete
                v-if="showEmployee"
                rounded
                v-model="employeeId"
                @change="getEmployeeReceipt"
                :items="users"
                item-text="userName"
                item-value="idUser"
                outlined
                label="الموظف"
              ></v-autocomplete>

              <v-text-field :disabled="showEmployee" rounded outlined v-model="totalAmount" type="number" label="المبلغ"></v-text-field>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="notes" name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة قبض</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    users: Array,
    doctors: Array,
    doctorMaterials: Array,
  },
  data: () => ({
    show: false,
    overlay: false,
    showEmployee: false,
    showDate: false,
    isDoctorChosen: false,
    incomeAmount: "",
    generationTypeId: "",
    notes: "",
    patientId: "",
    employeeId: "",
    totalAmount: 0,
    materialDoctorId: "",
    allIncome: [],
    incomeType: [
      { name: "قبض داخلي", id: 2 },
      { name: "قبض خارجي", id: 1 },
    ],
    typeId: "",
  }),

  methods: {
    getIncomeType() {
      let self = this;

      if (self.typeId == 2) {
        self.showEmployee = true;
      } else {
        self.showEmployee = false;
      }
    },
    saveData() {
      let self = this;

      let data2 = {
        amount: self.totalAmount,
        notes: self.notes,
        employeeId: self.employeeId,
        createdBy: self.$store.state.userData.idUser,
        isExternal: 2,
      };

      console.log(data2);

      if (self.totalAmount && self.notes && self.showEmployee == true) {
        let ids = self.allIncome.map((income) => income.idMoneyIncome);
        let data = {
          amount: self.totalAmount,
          notes: self.notes,
          employeeId: self.employeeId,
          createdBy: self.$store.state.userData.idUser,
          isExternal: 2,
          ids: ids,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addMoneyReceipt`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else if (self.showEmployee == false) {
        let data = {
          amount: self.totalAmount,
          notes: self.notes,
          employeeId: self.$store.state.userData.idUser,
          createdBy: self.$store.state.userData.idUser,
          isExternal: 1,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addMoneyReceipt`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(self.productImage);
            console.log(res.data);

            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
    getEmployeeReceipt() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/moneyIncomesUser/${self.employeeId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.totalAmount = res.data.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);
          self.allIncome = res.data;
          self.overlay = false;
        })
        .catch((err) => {
          console.log(err);
          self.overlay = false;
          this.$toasted.success("حدث خطأ اثناء جلب البيانات");
        });
    },
  },
  computed: {
    materialFilter() {
      if (this.employeeId) {
        return this.doctorMaterials.filter((item) => {
          return item.employeeId == this.employeeId;
        });
      } else {
        return this.doctorMaterials;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
