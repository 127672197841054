<template>
  <v-row>
    <v-col cols="2" v-if="$store.state.userData.roleId != 6">
      <v-sheet rounded="xl" elevation="0">
        <v-list shaped style="border-radius: 20px !important">
          <v-subheader>القائمة الرئيسية</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary" mandatory>
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-col>

    <v-col v-if="$store.state.userData.roleId == 1">
      <v-sheet min-height="70vh" rounded="xl" elevation="0">
        <HomeCenter v-if="selectedItem == 0" />
        <Doctors v-if="selectedItem == 1" />
        <Sections v-if="selectedItem == 2" />
        <Clinics v-if="selectedItem == 3" />
        <Materials v-if="selectedItem == 4" />
        <Patient v-if="selectedItem == 5" />
        <Reserved v-if="selectedItem == 6" />
        <DoctorMaterial v-if="selectedItem == 7" />
        <Income v-if="selectedItem == 8" />
        <MoneyReceipt v-if="selectedItem == 9 && $store.state.userData.roleId == 1" />
        <ExpensesCenter v-if="selectedItem == 10 && $store.state.userData.roleId == 1" />
        <ExpensesType v-if="selectedItem == 11 && $store.state.userData.roleId == 1" />
      </v-sheet>
    </v-col>

    <v-col v-if="$store.state.userData.roleId == 5">
      <v-sheet min-height="70vh" rounded="xl" elevation="0">
        <Doctors v-if="selectedItem == 0" />
        <Sections v-if="selectedItem == 1" />
        <Clinics v-if="selectedItem == 2" />
        <Materials v-if="selectedItem == 3" />
        <Patient v-if="selectedItem == 4" />
        <Reserved v-if="selectedItem == 5" />
        <DoctorMaterial v-if="selectedItem == 6" />
        <Income v-if="selectedItem == 7" />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import Clinics from "../components/turathCenter/Clinics.vue";
import Sections from "../components/turathCenter/Sections.vue";
import Doctors from "../components/turathCenter/Doctors.vue";
import Materials from "../components/turathCenter/Materials.vue";
import Patient from "../components/turathCenter/Patient.vue";
import Reserved from "../components/turathCenter/Reserved.vue";
import DoctorMaterial from "../components/turathCenter/DoctorMaterial.vue";
import Income from "../components/turathCenter/Income.vue";
import MoneyReceipt from "../components/turathCenter/MoneyReceipt.vue";
import HomeCenter from "../components/turathCenter/HomeCenter.vue";
import ExpensesType from "../components/turathCenter/ExpensesType.vue";
import ExpensesCenter from "../components/turathCenter/ExpensesCenter.vue";
export default {
  components: {
    Clinics,
    Sections,
    Doctors,
    Materials,
    Patient,
    Reserved,
    DoctorMaterial,
    Income,
    MoneyReceipt,
    HomeCenter,
    ExpensesType,
    ExpensesCenter,
  },
  name: "Home",
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "الرئيسية", icon: "mdi-doctor" },
      { text: "الاطباء", icon: "mdi-doctor" },
      { text: "الاقسام", icon: "mdi-layers" },
      { text: "العيادات", icon: "mdi-flask-round-bottom" },
      { text: "المواد", icon: "mdi-package" },
      { text: "المراجعين", icon: "mdi-badge-account-horizontal" },
      { text: "الحجوزات", icon: "mdi-calendar-account" },
      { text: "خدمات الطبيب", icon: "mdi-material-design" },
      { text: "القبض اليومي", icon: "mdi-cash-check" },
      { text: "الايرادات", icon: "mdi-cash-multiple" },
      { text: "المصروفات", icon: "mdi-cash-multiple" },
      { text: "انواع المصروفات", icon: "mdi-cash-multiple" },
    ],
  }),
  created() {
    console.log(this.$store.state.userData.roleId);
    if (this.$store.state.userData.roleId != 1) {
      this.items = [
        { text: "الاطباء", icon: "mdi-doctor" },
        { text: "الاقسام", icon: "mdi-layers" },
        { text: "العيادات", icon: "mdi-flask-round-bottom" },
        { text: "المواد", icon: "mdi-package" },
        { text: "المراجعين", icon: "mdi-badge-account-horizontal" },
        { text: "الحجوزات", icon: "mdi-calendar-account" },
        { text: "خدمات الطبيب", icon: "mdi-material-design" },
        { text: "المقبوضات", icon: "mdi-cash-check" },
      ];
    }
  },
  methods: {
    pageSelected() {
      console.log(this.selectedItem);
      if (this.selectedItem == 0) {
        return;
      } else {
      }
    },
  },
};
</script>
