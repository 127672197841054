<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الاساتذة
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddTeacher @reloadTask="initialData()" :trainingCenter="trainingCenter" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="dialog = false">
            <DeleteTeacher :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddTeacher from "./teacher/AddTeacher.vue";
import DeleteTeacher from "./teacher/DeleteTeacher.vue";
export default {
  components: {
    AddTeacher,
    DeleteTeacher,
  },
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    overlay: false,
    search: "",
    teachers: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الاستاذ", value: "teacherName" },
      { text: "رقم الهاتف", value: "teacherPhone" },
      { text: "المواليد", value: "dobFormat" },
      { text: "الوصف العلمي", value: "teacherBio" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    teachers: [],
    teachers: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/teachersCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((teachers) => {
            self.teachers = teachers.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.teachers.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.sectionName.toLowerCase().includes(v));
        });
      } else {
        return this.teachers;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
