<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة قبض يومي</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded outlined type="number" v-model="item.price" disabled label="المبلغ"></v-text-field>

              <v-text-field rounded outlined v-model="item.materialName" disabled label="المادة او التحليل"></v-text-field>

              <v-autocomplete rounded v-model="doctorId" :items="materialFilter" item-text="userName" item-value="idMaterialDoctor" outlined label="الطبيب"></v-autocomplete>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="item.notes" disabled name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small outlined color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-file-document-check </v-icon>
        </v-btn>
      </template>
      <span>اضافة قبض يومي</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    patientId: Number,
    doctorMaterials: Array,
    item: Object,
  },
  data: () => ({
    show: false,
    overlay: false,

    showDate: false,
    isDoctorChosen: false,
    incomeAmount: "",
    generationTypeId: "",
    incomeNote: "",
    doctorId: "",
    materialId: "",
  }),
  created() {
    console.log(this.item);
  },
  methods: {
    saveData() {
      let self = this;

      let data2 = {
        amount: self.item.price,
        notes: self.item.notes,
        materialDoctorId: self.doctorId,
        patientId: self.patientId,
        isValid: 1,
        isReceived: 0,
        createdBy: self.$store.state.userData.idUser,
      };

      console.log(data2);

      if (self.doctorId) {
        let data = {
          amount: self.item.price,
          notes: self.item.notes,
          materialDoctorId: self.doctorId,
          patientId: self.patientId,
          isValid: 1,
          isReceived: 0,
          createdBy: self.$store.state.userData.idUser,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addMoneyIncome`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            let updateReceiptMaterial = {
              receiptId: self.item.receiptId,
              materialId: self.item.materialId,
              notes: self.item.notes,
              price: self.item.price,
              isReceived: 2,
            };
            self.$http
              .put(`${self.$store.state.apiUrl}/receiptMaterial/${self.item.idReceiptMaterial}`, updateReceiptMaterial, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((response) => {
                console.log(self.productImage);
                console.log(res.data);

                this.$toasted.success("تم اضافه البيانات");
                this.$emit("reloadTask");
              });
          });
      } else {
        this.$toasted.error("الرجاء ملئ كافة الحقول");
      }

      this.show = false;
    },
    doctorChosen() {
      let filterDa;
    },
  },
  computed: {
    materialFilter() {
      return this.doctorMaterials.filter((item) => {
        return item.materialId == this.item.materialId;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
