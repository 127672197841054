<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="py-5" style="border-radius: 16px 16px 0px 0px !important; background: #05849d">
        <h4 style="color: #ffffff">اسم الطالب : {{ student.studentName }}</h4>
        <span style="width: 50px"></span>
        <h4 style="color: #ffffff">الجنس : {{ student.gender == 1 ? "ذكر" : "انثى" }}</h4>
        <span style="width: 50px"></span>
        <h4 style="color: #ffffff">المواليد : {{ student.dobFormat }}</h4>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="backButton()" size="35" color="background" v-bind="attrs" v-on="on"> mdi-arrow-left-thin-circle-outline </v-icon>
          </template>
          <span>رجوع</span>
        </v-tooltip>
      </v-card-title>
      <div v-if="!overlay">
        <div v-for="(course, index) in courses" :key="index">
          <v-card color="white" elevation="0" style="border-radius: 20px !important">
            <v-card-title class="warning-color" style="border-radius: 0px !important; background: #eee">
              <h4>اسم الكورس : {{ course.courseName }}</h4>
              <v-spacer></v-spacer>
              <h4>
                سعر الكورس :
                {{
                  course.courseCost.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>

              <v-spacer></v-spacer>

              <div style="width: 20px"></div>
              <AddStudentPayment @reloadTask="initialData()" :types="types" :cost="course.courseCost" :courseId="course.idCourse" :studentId="student.idStudent" />
            </v-card-title>
            <v-data-table :headers="headers" :items="studentPayments.filter((pay) => pay.courseId == course.idCourse)" :items-per-page="15" class="table-content table-warning-color">
              <template v-slot:[`item.index`]="{ index }">
                <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <h4 style="padding: 0 3px">
                  {{
                    item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h4>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="printVoucher(item, course)">
                  <v-icon color="info"> mdi-printer </v-icon>
                </v-btn>
                <DeleteStudentPayment :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" style="display: inline-block; margin-right: 20px" />
              </template>
            </v-data-table>
          </v-card>
          <v-divider></v-divider>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import AddStudentPayment from "./studentPayment/AddStudentPayment.vue";
import DeleteStudentPayment from "./studentPayment/DeleteStudentPayment.vue";
import n2wordsAR from "n2words";
export default {
  components: {
    AddStudentPayment,
    DeleteStudentPayment,
  },
  props: {
    student: Object,
  },
  data: () => ({
    overlay: true,
    courses: [],
    items: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "idStudentPayment" },
      { text: "تاريخ الدفعة", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "نوع الدفعة", value: "paymentTypeName" },
      { text: "بواسطة", value: "userName" },
      { text: "الملاحظات", value: "notes" },
      { text: "الاجراءات", value: "actions" },
    ],
    studentPayments: [],
    types: [],
  }),
  created() {
    this.initialData();
  },
  destroyed() {
    let self = this;
    self.$store.state.showStudentCourses = false;
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/studentCoursesByStudentId/${self.student.idStudent}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/studentPaymentsForStudent/${self.student.idStudent}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/paymentTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((courses, studentPayments, types) => {
            self.courses = courses.data;
            self.studentPayments = studentPayments.data;
            self.types = types.data;
            console.log(studentPayments.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    backButton() {
      let self = this;
      self.$store.state.showStudentCourses = false;
    },
    printVoucher(voucher, course) {
      let self = this;

      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // <div data-v-4a284cfa="" class="col col-4">
      //               <h3 data-v-4a284cfa="" style="padding-top:10px;text-align:center;padding-right:20px">
      //                 <img src="${image2}"  width="170px"/>
      //               </h3>
      //             </div>

      let image = require("./../../assets/logo.png");
      let image2 = require("./../../assets/turath.png");
      let image3 = require("./../../assets/3.png");

      console.log(image);

      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });

      WinPrint.document.write(`<!DOCTYPE html>
      <!DOCTYPE html>
        <html lang="ar">
        ${stylesHtml}
          <style>
          body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
           

          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
               
                page-break-before: avoid;
                page-break-before: avoid;
                
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              
              background-size:cover !important;
            }
            .print-padding {
              padding: 5px !important;
            } 
          </style>
          <body >
            <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 0px;height:99vh;">
              <div data-v-4a284cfa="">
                <div data-v-4a284cfa="" class="row print-padding">
                
                  <div data-v-4a284cfa="" class="col col-6">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 100px;text-align:left;padding: 10px 20px">
                      <img src="${image}"  width="100px"/>
                    </div>
                  </div>

                  

                   <div data-v-4a284cfa="" class="col col-6">
                    <h3 data-v-4a284cfa="" style="padding-top:0px;text-align:right;padding-right:20px">
                      <img src="${image3}"  width="90px"/>
                    </h3>
                  </div>
               
                </div>
                <div data-v-4a284cfa="">
                 
                  <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  <div style="height:30px"></div>
                  <div data-v-4a284cfa="" class="row">
                    
                   

                       <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.idStudentPayment}
                      </h3>
                      <h3 style="display:inline-block;">
                        : رقم الوصل  
                      </h3>
                    </div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                    <div data-v-4a284cfa="" class="col col-4"">
                        <h3 style="display:inline-block">
                        ${voucher.paymentDateFormat}
                      </h3>
                      <h3 style="display:inline-block">
                        : التاريخ  
                      </h3>
                    </div>
                 

                  </div>

                  <div style="height:50px"></div>


                    <h2 style="display:inline-block"> وصل قبض التدريب الصيفي </h2>

                  <div></div>

                    <div style="height:40px"></div>

                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                      
                    </div>

                    <div data-v-4a284cfa="" class="col col-4"></div>

                    <div data-v-4a284cfa="" class="col col-4" >
                      
                      <p style="display:inline-block;font-size:16px">
                        ${self.student.studentName}
                      </p>
                      <p style="display:inline-block;font-size:16px;">
                        : اسم الطالب  
                      </p>
                      
                    </div>

                  </div>

                  <table width="100%" style="padding:30px">

                    <tr style="border:1px solid #000">
                      <th  style="border:1px solid #000">
                        <h3>الملاحظات</h3>
                        
                      </th>
                    
                      <th  style="border:1px solid #000">
                        <h3>المبلغ</h3>
                        </th>
                      <th  style="border:1px solid #000">
                      <h3>اسم الكورس</h3>
                      </th>
                    </tr>
                    <tr style="border:1px solid #000">
                      <td  style="border:1px solid #000">
                        <h3> ${voucher.notes ? voucher.notes : "لايوجد"}</h3>
                        
                       </td>
                      
                      <td  style="border:1px solid #000">
                        <h3>${voucher.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })}</h3>
                      </td>
                      <td  style="border:1px solid #000"><h3>${course.courseName}</h3></td>
                    
                    </tr>
                    
                  </table>


                  <div style="height:80px"></div>


                  <div data-v-4a284cfa="" class="row">
                    
                    <div data-v-4a284cfa="" class="col col-4"">
                    
                    </div>

                    <div data-v-4a284cfa="" class="col col-4">
                    
                    </div>

                    <div data-v-4a284cfa="" class="col col-4">
                      <h3 style="display:inline-block;">
                        ${voucher.userName}
                      </h3>
                      <h3 style="display:inline-block;">
                        : اسم الموظف المختص  
                      </h3>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </body>
        </html>
        `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
