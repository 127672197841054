<template>
  <div>
    <v-dialog v-model="show" persistent max-width="900px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة كورس للطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="6">
              <v-autocomplete rounded v-model="courseId" dense :items="courses" item-text="courseName" item-value="idCourse" outlined label="الكورسات"></v-autocomplete>
              <v-btn outlined rounded color="success" @click="saveData"> اضافة كورس </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <h3>كورسات الطالب</h3>
              <div style="height: 10px"></div>
              <v-divider></v-divider>
              <div style="height: 20px"></div>
              <div v-if="courseForStudent.length == 0">
                <v-alert icon="mdi-close-circle" text type="error"> لاتوجد كورسات مسجلة </v-alert>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="3"><h5>اسم الكورس</h5></v-col>
                  <v-col cols="3"><h5>عدد ايام الكورس</h5></v-col>
                  <v-col cols="3"><h5>التكلفة</h5></v-col>
                  <v-col cols="3"><h5>الاجراءات</h5></v-col>
                </v-row>
                <div style="height: 3px"></div>
                <v-divider></v-divider>
                <div style="height: 3px"></div>

                <v-row v-for="(course, index) in courseForStudent" :key="index">
                  <v-col cols="3">{{ course.courseName }}</v-col>
                  <v-col cols="3">{{ course.duration }}</v-col>
                  <v-col cols="3">{{ course.courseCost }}</v-col>
                  <v-col cols="3">
                    <v-icon v-if="$store.state.userData.roleId == 1" @click="deleteCourse(course)" color="error"> mdi-delete-circle </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="show = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-1" small icon color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-plus-circle </v-icon>
        </v-btn>
      </template>
      <span>اضافة كورس للطاالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    courses: Array,
    studentId: Object,
  },
  data: () => ({
    show: false,
    overlay: false,
    courseForStudent: [],
    userName: "",
    specialization: "",
    dealPercentage: "",
    courseId: "",
    phone: "",
    userImage: "",
    files: [],
  }),
  created() {
    this.getStudentCourse();
  },
  methods: {
    getStudentCourse() {
      let self = this;
      self.courseForStudent = [];
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/studentCoursesByStudentId/${self.studentId.idStudent}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.courseForStudent = res.data;
          self.overlay = false;
        });
    },
    getAllFiles() {
      let self = this;
      if (this.files.length > 0) {
        self.overlay = true;
        let formData = new FormData();
        self.files.forEach((file, index) => {
          console.log(index);
          formData.append("attachment", file);
        });
        self.$http.post(`${self.$store.state.apiUrl}/uploadAppImage`, formData).then((res) => {
          self.overlay = false;
          self.userImage = res.data;
          console.log(res);
        });
      }
    },
    deleteCourse(course) {
      let self = this;
      self.$http
        .delete(`${self.$store.state.apiUrl}/studentCourse/${course.idStudentCourse}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toasted.error("تم حذف البيانات");
          self.getStudentCourse();
        });
    },
    saveData() {
      let self = this;

      let filterCourse = self.courseForStudent.filter((course) => course.courseId == self.courseId);
      console.log(filterCourse);

      if (self.courseId && filterCourse.length == 0) {
        let data = {
          studentId: self.studentId.idStudent,
          courseId: self.courseId,
        };

        self.$http
          .post(
            `${self.$store.state.apiUrl}/addStudentCourse`,

            data,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          )
          .then((res) => {
            console.log(res.data);
            this.$toasted.success("تم اضافه البيانات");
            this.getStudentCourse();
          });
      } else {
        this.$toasted.error("حدث خطأ في حفظ البيانات او قد تكون العيادة مسجلة للطبيب");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
