<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="100%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل ايراد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-row>
            <v-col cols="12">
              <v-text-field rounded disabled outlined type="number" v-model="itemToEdit.incomeAmount" label="مبلغ الايراد"></v-text-field>

              <v-autocomplete rounded disabled v-model="itemToEdit.currencyId" :items="currencies" item-text="currencyName" item-value="idCurrency" outlined label="العملة"></v-autocomplete>

              <v-autocomplete
                rounded
                v-model="itemToEdit.generationResourceId"
                :items="resources"
                item-text="resourceName"
                item-value="idGenerationResource"
                outlined
                label="مصدر الايراد"
              ></v-autocomplete>

              <v-text-field rounded outlined type="date" v-model="itemToEdit.dateFormat" label="تاريخ الصرفية"></v-text-field>

              <v-textarea rounded rows="3" row-height="15" outlined v-model="itemToEdit.incomeNote" name="input-7-4" label="الملاحظات"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon @click="show = true" color="edit" v-bind="attrs" v-on="on"> mdi-pencil-circle </v-icon>
      </template>
      <span>تعديل صرفية</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    itemToEdit: Object,
    currencies: Array,
    resources: Array,
  },
  data: () => ({
    show: false,
    overlay: false,

    showDate: false,
    incomeDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    incomeAmount: "",
    generationTypeId: "",
    incomeNote: "",
    currencyId: "",
    resourceId: "",
  }),
  created() {
    console.log(this.itemToEdit);
    this.itemToEdit.dateFormat = this.itemToEdit.incomeDate.split("T")[0];
  },
  methods: {
    saveData() {
      let self = this;

      let data = {
        incomeDate: self.itemToEdit.dateFormat,
        incomeAmount: self.itemToEdit.incomeAmount,
        incomeNote: self.itemToEdit.incomeNote,
        currencyId: self.itemToEdit.currencyId,
        generationResourceId: self.itemToEdit.generationResourceId,
        isValid: 1,
        createdBy: self.$store.state.userData.idUser,
      };

      self.$http
        .put(`${self.$store.state.apiUrl}/generationIncome/${self.itemToEdit.idGenerationIncome}`, data, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);

          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        });

      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
