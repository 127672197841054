import { render, staticRenderFns } from "./DeleteExpensesCenter.vue?vue&type=template&id=48ba7938&scoped=true"
import script from "./DeleteExpensesCenter.vue?vue&type=script&lang=js"
export * from "./DeleteExpensesCenter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ba7938",
  null
  
)

export default component.exports