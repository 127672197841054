<template>
  <div>
    <v-dialog v-model="showDeleteDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="success" elevation="0">
          <v-btn icon dark @click="showDeleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تأكيد دخول</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <h3 style="color: #1b998b">هل انت متأكد من المراجع قد اتم مراجعة الطبيب ؟</h3>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="confirmDelete"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-icon @click="showDeleteDialog = true" color="success"> mdi-check </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    patient: Object,
  },
  data: () => ({
    datePickerAddModel: false,
    showDeleteDialog: false,
    addedDate: "",
    files: [],
  }),
  methods: {
    confirmDelete() {
      let self = this;
      let dateReserved = new Date(self.patient.reservedDate);
      let updateEnteredDate = `${dateReserved.getFullYear()}-${dateReserved.getMonth() + 1}-${dateReserved.getDate()}`;
      console.log(updateEnteredDate);
      let data = {
        patientId: self.patient.patientId,
        reservedDate: updateEnteredDate,
        reservedTime: self.patient.reservedTime,
        isEntered: 1,
        clinicId: self.patient.clinicId,
        doctorId: self.patient.doctorId,
        notes: self.patient.notes,
      };
      self.$http
        .put(`${self.$store.state.apiUrl}/patientReserved/${self.patient.idPatientReserved}`, data, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toasted.success("تم تأكيد المراجعة");
          self.showDeleteDialog = false;
          this.$emit("reloadTask");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
