import { render, staticRenderFns } from "./DeleteBenefit.vue?vue&type=template&id=343089f0&scoped=true"
import script from "./DeleteBenefit.vue?vue&type=script&lang=js"
export * from "./DeleteBenefit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343089f0",
  null
  
)

export default component.exports