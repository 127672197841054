<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        مقبوضات الطلبة
        <v-spacer></v-spacer>
        <AddStudentPayment @reloadTask="allStudentPayments()" v-if="showAddPayment" :types="types" :studentId="studentId" :courseId="courseId" />
        <v-spacer></v-spacer>
        <SearchStudentCourse @searchData="searchPayment" :courses="courses" :students="students" />
      </v-card-title>
      <v-data-table :headers="headers" :items="studentPayments" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="dialog = false">
            <DeleteStudentPayment :dataToDelete="item" @reloadTask="initialData()" v-if="$store.state.userData.roleId == 1" />
          </v-btn>
        </template>
      </v-data-table>
      <div style="padding: 20px">
        <h3>
          القبض اليومي :
          {{
            (paymentAmount.payAmount * 1).toLocaleString("en-US", {
              style: "currency",
              currency: "IQD",
            })
          }}
        </h3>
      </div>
    </v-card>
  </div>
</template>

<script>
import SearchStudentCourse from "./studentPayment/SearchStudentCourse.vue";
import AddStudentPayment from "./studentPayment/AddStudentPayment.vue";
import DeleteStudentPayment from "./studentPayment/DeleteStudentPayment.vue";
export default {
  components: {
    SearchStudentCourse,
    AddStudentPayment,
    DeleteStudentPayment,
  },
  props: {
    trainingCenter: Number,
  },
  data: () => ({
    overlay: false,
    search: "",
    showAddPayment: false,
    studentId: "",
    courseId: "",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رثم الوصل", value: "idStudentPayment" },
      { text: "تاريخ الدفعة", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      { text: "نوع الدفعة", value: "paymentTypeName" },
      { text: "بواسطة", value: "userName" },
      { text: "الاجراءات", value: "actions" },
    ],
    currencies: [],
    students: [],
    courses: [],
    types: [],
    studentPayments: [],
    paymentAmount: "",
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      self.showAddPayment = false;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/studentTrainingCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/courseTrainingCenter/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/paymentTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/studentPaymentsToday/${self.trainingCenter}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((students, courses, types, paymentAmount) => {
            self.students = students.data;
            self.courses = courses.data;
            self.types = types.data;
            self.paymentAmount = paymentAmount.data;
            console.log(paymentAmount);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    allStudentPayments() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrl}/studentPaymentsForCourse?studentId=${self.studentId}&courseId=${self.courseId}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.studentPayments = res.data;
        });
    },
    searchPayment(value) {
      let self = this;
      console.log(value);
      self.studentPayments = value.data;
      self.studentId = value.studentId;
      self.courseId = value.courseId;
      self.showAddPayment = true;
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.resources.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.sectionName.toLowerCase().includes(v));
        });
      } else {
        return this.resources;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
