import { render, staticRenderFns } from "./DeleteStudent.vue?vue&type=template&id=276ff7a6&scoped=true"
import script from "./DeleteStudent.vue?vue&type=script&lang=js"
export * from "./DeleteStudent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276ff7a6",
  null
  
)

export default component.exports