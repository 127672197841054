<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        المصروفات
        <v-spacer></v-spacer>
        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddExpensesCenter @reloadTask="initialData()" :expensesTypes="expensesTypes" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <h4 style="padding: 0 3px">
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h4>
        </template>

        <template v-slot:[`item.isExternal`]="{ item }">
          <span>
            {{ item.isExternal == 1 ? "قبض خارجي" : "قبض داخلي" }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <DeleteExpensesCenter @reloadTask="initialData" :dataToDelete="item" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddExpensesCenter from "./expensesCenter/AddExpensesCenter.vue";
import DeleteExpensesCenter from "./expensesCenter/DeleteExpensesCenter.vue";
export default {
  components: {
    AddExpensesCenter,
    DeleteExpensesCenter,
  },
  data: () => ({
    overlay: false,
    search: "",
    expensesCenters: [],
    doctors: [],
    headers: [
      { text: "العدد", value: "index" },
      { text: "المبلغ", value: "amount" },
      { text: "التاريخ", value: "expensesDateFormat" },
      { text: "التبويب", value: "typeName" },
      { text: "التفاصيل", value: "notes" },
      { text: "الاجراءات", value: "actions" },
    ],
    expensesTypes: [],
    doctorMaterials: [],
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/expensesCenters`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),

          self.$http.get(`${self.$store.state.apiUrl}/expensesTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((expensesCenters, expensesTypes) => {
            self.expensesCenters = expensesCenters.data;
            self.expensesTypes = expensesTypes.data;
            console.log(expensesCenters.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.expensesCenters.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.patientName.toLowerCase().includes(v));
        });
      } else {
        return this.expensesCenters;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
