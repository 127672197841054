<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card v-if="!$store.state.showPatientHistory" color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        المراجعين
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <AddPatient @reloadTask="initialData()" :clinics="clinics" :patients="patients" />
      </v-card-title>
      <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <template v-slot:[`item.gender`]="{ item }">
          <h4 style="padding: 0 3px">{{ item.gender == 1 ? "ذكر" : "انثى" }}</h4>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="selectPatient(item)" color="info" v-bind="attrs" v-on="on"> mdi-file-account </v-icon>
            </template>
            <span>التاريخ الطبي</span>
          </v-tooltip>

          <div style="width: 20px; display: inline-block"></div>

          <DeletePatient @reloadTask="initialData" :dataToDelete="item" style="display: inline-block" v-if="$store.state.userData.roleId == 1" />
        </template>
      </v-data-table>
    </v-card>
    <PatientHistory v-else :patient="patientSelected" />
  </div>
</template>

<script>
import AddPatient from "./patients/AddPatient.vue";
import DeletePatient from "./patients/DeletePatient.vue";
import PatientHistory from "./PatientHistory.vue";
export default {
  components: {
    AddPatient,
    DeletePatient,
    PatientHistory,
  },
  data: () => ({
    overlay: false,
    search: "",
    patients: [],
    clinics: [],
    patientSelected: {},
    headers: [
      { text: "العدد", value: "index" },
      { text: "اسم المراجع", value: "patientName" },
      { text: "المواليد", value: "dobFormat" },
      { text: "الجنس", value: "gender" },
      { text: "رقم الهاتف", value: "patientPhone" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),
  created() {
    this.initialData();
  },
  methods: {
    selectPatient(patient) {
      let self = this;
      self.$store.state.showPatientHistory = true;
      self.patientSelected = patient;
    },
    initialData() {
      let self = this;

      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/patients`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrl}/clinics`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((patients, clinics) => {
            self.patients = patients.data;
            self.clinics = clinics.data;
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.patients.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.patientName.toLowerCase().includes(v));
        });
      } else {
        return this.patients;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
